import React, { createContext, useState, useContext, useMemo } from 'react';

const Context = createContext({
  profile: null,
  setProfile: () => null,
  spaces: 0,
  setSpaces: () => null,
  bookingType: '',
  setBookingType: () => null,
  start: null,
  setStart: () => null,
  end: null,
  setEnd: () => null,
  chatrooms: [],
  addChatroom: () => null,
  removeChatroom: () => null,
  updateChatroomState: () => null,
  resetContext: () => null,
});

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [spaces, setSpaces] = useState(1);
  const [bookingType, setBookingType] = useState('HD');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [chatrooms, setChatrooms] = useState([]);

  const addChatroom = (chatroom) => {
    setChatrooms((prevChatrooms) => [
      ...prevChatrooms,
      { ...chatroom, windowState: 'normal' },
    ]);
  };

  const removeChatroom = (slug) => {
    setChatrooms((prevChatrooms) =>
      prevChatrooms.filter((cr) => cr.slug !== slug)
    );
  };

  const updateChatroomState = (slug, newState) => {
    setChatrooms((prevChatrooms) => {
      const updatedChatrooms = prevChatrooms.map((cr) =>
        cr.slug === slug ? { ...cr, windowState: newState } : cr
      );
      // Save updated chatrooms to localStorage
      localStorage.setItem('chatrooms', JSON.stringify(updatedChatrooms));
      return updatedChatrooms;
    });
  };

  const resetContext = () => {
    setProfile(null);
    setSpaces(1);
    setBookingType('HD');
    setStart(null);
    setEnd(null);
    setChatrooms([]);
    localStorage.removeItem('chatrooms');
  };

  // Memoize the value object to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      profile,
      setProfile,
      spaces,
      setSpaces,
      bookingType,
      setBookingType,
      start,
      setStart,
      end,
      setEnd,
      chatrooms,
      addChatroom,
      removeChatroom,
      updateChatroomState,
      resetContext,
    }),
    [profile, spaces, bookingType, start, end, chatrooms] // Recompute only when these dependencies change
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useProfile = () => {
  return useContext(Context);
};

export default Context;
