import React, { useState, useEffect } from 'react'
import { Container, Tabs, Tab, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import GeneralInfoForm from './generalInfo'
import Gallery from './gallery'
import Tariffs from './tariffs'
import OpeningTime from './opening'
import { getVenueStaff } from 'api' // Assuming you have an API utility

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function VenueSettings({ slug }) {
  const [tabValue, setTabValue] = useState(0);
  const [venueData, setVenueData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchVenueData = async () => {
      setLoading(true)
      try {
        const response = await getVenueStaff(slug)
        console.log(response)

        setVenueData(response?.venue)
        


      } catch (err) {
        console.error(err)
        setError('An error occurred while fetching the venue data.')
      } finally {
        setLoading(false)
      }
    }

    fetchVenueData()
  }, [slug])

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Update Venue Information
      </Typography>

      {/* Tab Selector */}
      <Tabs value={tabValue} onChange={handleChange} aria-label="Venue Tabs">
        <Tab label="General Info" />
        <Tab label="Gallery" />
        <Tab label="Tariffs" />
        <Tab label="Opening time" />
      </Tabs>

      {/* Tab Panels */}
      <TabPanel value={tabValue} index={0}>
        <GeneralInfoForm venueData={venueData} />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Gallery venueData={venueData} />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Tariffs slug={slug} />
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        <OpeningTime venueData={venueData} />
      </TabPanel>
    </Container>
  );
}

VenueSettings.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default VenueSettings;
