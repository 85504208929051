import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom'

import View from './view'

const ResetForm = ({ ...props }) => {
  const onSuccess = props.onSuccess
  const setShowReset = props.setShowReset
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { doReset } = useAuth();

  const mutateReset = useMutation(data => doReset(data), {
    onSuccess: result => {
      console.log('Password reset successful ', result, result?.message)
      if (result?.message === 'email_not_found') {
        enqueueSnackbar(('Email address not found'), {
          variant: 'error',
        })
        
      }
      if (result?.message === 'email_sent') {
        enqueueSnackbar(t('reset.success'), {
          variant: 'success',
        })
        //onSuccess(result)
      }
      
      //navigate('/communities')
    },
    onError: (error) => {
      console.error('Password reset failed', error)
      enqueueSnackbar(t('reset.error', error), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    console.log('Reset password data:', data)
    mutateReset.mutate(data)
  }

  return <View {...props} onSubmit={onSubmit} onSuccess={onSuccess} setShowReset={setShowReset} />
}

export default ResetForm
