import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Checkbox, FormControlLabel, TextField, Slider, IconButton, Switch, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getTariffs, updateTariff, createTariffRule } from 'api';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Tariffs = ({ openModal }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [tariffs, setTariffs] = useState([]);
    const [tariffRules, setTariffRules] = useState([]);
    const [showNewRuleForm, setShowNewRuleForm] = useState(false);
    const [newRule, setNewRule] = useState({
        tariff: '',
        days_of_week: [],
        occupancy_min: 0,
        occupancy_max: 1,
        unit_price: 0,
    });
    const [isVatInclusive, setIsVatInclusive] = useState(false); // State for VAT inclusion

    const getTariffInfo = async () => {
        try {
            const response = await getTariffs();
            setTariffs(response.tariffs);
            if (response.tariffs?.length > 0) {
                setNewRule({ ...newRule, tariff: response.tariffs[0].slug });
                setTariffRules(response.tariffs[0]?.rules || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addTariffRule = async () => {
        try {
            const response = await createTariffRule(newRule);
            console.log('New rule created:', response);
        } catch (error) {
            console.error('Error creating new rule:', error);
        }
    };

    useEffect(() => {
        getTariffInfo();
    }, []);

    const handleDayToggle = (ruleIndex, day) => {
        const updatedRules = tariffRules.map((rule, index) => {
            if (index === ruleIndex) {
                const days = rule.days_of_week.includes(day)
                    ? rule.days_of_week.filter(d => d !== day)
                    : [...rule.days_of_week, day];
                return { ...rule, days_of_week: days };
            }
            return rule;
        });
        setTariffRules(updatedRules);
    };

    const handleSliderChange = (ruleIndex, newValue) => {
        const updatedRules = tariffRules.map((rule, index) => {
            if (index === ruleIndex) {
                return { ...rule, occupancy_min: newValue[0] / 100, occupancy_max: newValue[1] / 100 };
            }
            return rule;
        });
        setTariffRules(updatedRules);
    };

    const handleInputChange = (ruleIndex, value) => {
        const updatedRules = tariffRules.map((rule, index) => {
            if (index === ruleIndex) {
                return { ...rule, unit_price: Math.max(0, value) };
            }
            return rule;
        });
        setTariffRules(updatedRules);
    };

    const handleTariffPriceChange = (index, value) => {
        const updatedTariffs = tariffs.map((tariff, i) => {
            if (i === index) {
                return { ...tariff, unit_price: Math.max(0, value) };
            }
            return tariff;
        });
        setTariffs(updatedTariffs);
    };

    const handleNewRuleChange = (field, value) => {
        setNewRule(prev => ({ ...prev, [field]: value }));
    };

    const handleNewRuleDayToggle = (day) => {
        const days = newRule.days_of_week.includes(day)
            ? newRule.days_of_week.filter(d => d !== day)
            : [...newRule.days_of_week, day];
        setNewRule({ ...newRule, days_of_week: days });
    };

    const handleSave = async () => {
        if (tariffs?.length > 0) {
            const tariffData = {
                slug: tariffs[0].slug,
                tariff: {
                    name: tariffs[0].name,
                    status: tariffs[0].status,
                    start: tariffs[0].start,
                    end: tariffs[0].end,
                    unit_price: tariffs[0].unit_price,
                },
                rules: tariffRules,
            };
            try {
                const response = await updateTariff(tariffData);
                console.log('Tariff updated successfully:', response);
            } catch (error) {
                console.error('Error updating tariff:', error);
            }
        }
    };

    // Function to calculate display price based on VAT inclusion
    const getDisplayPrice = (unitPrice) => {
        let price = parseFloat(unitPrice); // Ensure unitPrice is a number

        if (isVatInclusive) {
            price *= 1.2; // Assuming you want to multiply by 1.2 to add VAT
        }

        // Check if price is a valid number before calling toFixed
        if (!isNaN(price)) {
            return price.toFixed(2); // Return price as a string with two decimal places
        }

        return '0.00'; // Fallback if price is not a valid number
    };

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginBottom: '20px' }}>
            <FormControlLabel
                control={<Switch checked={isVatInclusive} onChange={(e) => setIsVatInclusive(e.target.checked)} />}
                label={isVatInclusive ? "VAT inclusive" : "VAT exclusive"}
            />
            {tariffs?.map((tariff, index) => (
                <Grid item xs={12} key={index}>
                    <h3>{tariff.name}</h3>
                    <TextField
                        label="Unit Price"
                        type="number"
                        value={getDisplayPrice(tariff.unit_price)}
                        onChange={(e) => handleTariffPriceChange(index, e.target.value)}
                        fullWidth
                        margin="normal"
                        sx={{ textAlign: 'right' }}
                    />

                    {/* Map over tariffRules */}
                    {tariffRules?.map((rule, ruleIndex) => (
                        <div key={rule.slug}>
                            {/* Days of the Week Checkboxes */}
                            <div>
                                {daysOfWeek?.map((day, dayIndex) => (
                                    <FormControlLabel
                                        key={day}
                                        control={
                                            <Checkbox
                                                checked={rule.days_of_week.includes(dayIndex)}
                                                onChange={() => handleDayToggle(ruleIndex, dayIndex)}
                                            />
                                        }
                                        label={day}
                                    />
                                ))}
                            </div>

                            {/* Occupancy Range Slider and Unit Price */}
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={9}>
                                    <Slider
                                        value={[rule.occupancy_min * 100, rule.occupancy_max * 100]}
                                        onChange={(e, newValue) => handleSliderChange(ruleIndex, newValue)}
                                        valueLabelDisplay="auto"
                                        step={10}
                                        marks
                                        min={0}
                                        max={100}
                                        color='secondary'
                                        label="Occupancy Range"
                                        sx={{ marginRight: '10px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Unit Price"
                                        type="number"
                                        value={getDisplayPrice(rule.unit_price)}
                                        onChange={(e) => handleInputChange(ruleIndex, e.target.value)}
                                        inputProps={{ min: 0 }} // Ensure price is not lower than 0
                                        fullWidth
                                        margin="normal"
                                        sx={{ textAlign: 'right' }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}

                    {/* Add New Rule Icon Button */}
                    <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                        <IconButton onClick={() => setShowNewRuleForm(!showNewRuleForm)}>
                            <AddIcon fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            {/* New Rule Form - Conditionally Rendered */}
            {showNewRuleForm && (
                <Grid item xs={12}>
                    <h3>New Rule</h3>
                    <div>
                        {daysOfWeek.map((day, dayIndex) => (
                            <FormControlLabel
                                key={day}
                                control={
                                    <Checkbox
                                        checked={newRule.days_of_week.includes(dayIndex)}
                                        onChange={() => handleNewRuleDayToggle(dayIndex)}
                                    />
                                }
                                label={day}
                            />
                        ))}
                    </div>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={9}>
                            <Slider
                                value={[newRule.occupancy_min * 100, newRule.occupancy_max * 100]}
                                onChange={(e, newValue) => handleNewRuleChange('occupancy_min', newValue[0] / 100)}
                                valueLabelDisplay="auto"
                                step={10}
                                marks
                                min={0}
                                max={100}
                                color='secondary'
                                label="Occupancy Range"
                                sx={{ marginRight: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Unit Price"
                                type="number"
                                value={getDisplayPrice(newRule.unit_price)}
                                onChange={(e) => handleNewRuleChange('unit_price', e.target.value)}
                                inputProps={{ min: 0 }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="secondary" onClick={() => addTariffRule()}>
                        Add New
                    </Button>
                </Grid>
            )}

            {/* Save Button */}
            <Grid item xs={12}>
                <Box mt={4} display="flex" justifyContent="center">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {t('Save changes')}
                    </Button>
                </Box>
            </Grid>

        </Grid>
    );
};

Tariffs.propTypes = {
    openModal: PropTypes.func,
};

export default Tariffs;
