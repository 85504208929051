import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'config/keys';
import { useNavigate } from 'react-router-dom';

import coworking from 'assets/images/coworking.jpg';
import home_hero from 'assets/images/home_hive.jpg';

const defaultCenter = {
  lat: 51.52268795372802,
  lng: -0.08518920377573923,
};


const HomeHeader = ({ title = "Find your work space at the best price", subtitle = "", background }) => {
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [coordinates, setCoordinates] = useState(defaultCenter);
  const [postcode, setPostcode] = useState('');
  const mapRef = useRef(null);
  const lastCoordinates = useRef(coordinates); // Add this to track last coordinates
  const navigate = useNavigate();

  const handleSearch = () => {
    // Construct query parameters
    const params = new URLSearchParams({
      location: `${coordinates.lat},${coordinates.lng}`, // Format location as "lat,lng"
      date,                                             // Include date
      postcode,                                         // Add postcode
    });

    // Navigate to the desired route with the query string
    navigate(`/booking-find?${params.toString()}`);
  };


  const libraries = ['places'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const backgroundImage = background || home_hero;

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setCoordinates(newCoordinates);
        setPostcode(place.formatted_address);

        if (mapRef.current) {
          mapRef.current.panTo(newCoordinates);
        }
      }
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <CircularProgress />;

  return (
    <Box
      sx={{
        position: 'relative',
        height: '70vh', // Adjusted height to 70vh
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        padding: 3,
      }}
    >
      {/* Title and Subtitle */}
      <Box sx={{ marginTop: 'auto' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="h6" sx={{ opacity: 0.8 }}>
            {subtitle}
          </Typography>
        )}
      </Box>

      {/* Search Form */}
      <Box
        component="form"
        sx={{
          width: '100%',
          maxWidth: '800px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginBottom: '20px',
          flexWrap: 'wrap', // For responsiveness
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        {/* Location Input */}
        <Autocomplete
          onLoad={setAutocomplete}
          onPlaceChanged={handlePlaceSelect}
        >
          <TextField
            label="Postcode"
            variant="outlined"
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
            size="small"
            sx={{ flex: 1, minWidth: '200px' }}
            placeholder="Enter a postcode"
          />
        </Autocomplete>

        {/* Date Input */}
        <TextField
          label="Date"
          variant="outlined"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1, minWidth: '200px' }}
        />

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            flex: 1,
            minWidth: '150px',
            textTransform: 'none',
          }}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

HomeHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.string,
};

export default HomeHeader;
