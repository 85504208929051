import React, { useState, useEffect } from 'react'
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { getMessageBoard } from 'api'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder'

dayjs.extend(relativeTime)

const MessageList = ({ slug }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [campaigns, setCampaigns] = useState([])

  const fetchMessageBoard = () => {
    getMessageBoard(slug).then(r => {
      console.log(r)
      const extractedCampaigns = r?.campaign_users.map(userCampaign => userCampaign.campaign) || []
      setCampaigns(extractedCampaigns)
    })
  }

  useEffect(() => {
    fetchMessageBoard()
  }, [])

  const getDisplayDate = (date) => {
    const oneMonthAgo = dayjs().subtract(1, 'month')
    return dayjs(date).isAfter(oneMonthAgo) ? dayjs(date).fromNow() : dayjs(date).format('DD/MM/YYYY HH:mm')
  }

  return (
    <Container>
      <Grid container spacing={3} sx={{ paddingTop: 5 }}>
        {campaigns?.length === 0 && (
          <Grid item xs={12}>
            <NoResultsPlaceholder firstSentence={t('No messages found')} lastSentence={t('Stay put!')} />
          </Grid>
        )}
        {campaigns?.map((item, index) => {
          const displayDate = getDisplayDate(item?.planned_sent);
          const content = item?.body; // Assuming the content is stored in 'body'
          const senderName = item?.sender ? `${item.sender.first_name} ${item.sender.last_name}` : 'Admin'; // Assuming the sender is the admin

          const isFeatured = item?.featured > 0;

          return (
            <Grid item xs={12} key={index}>
              <Card sx={{ width: '100%', backgroundColor: isFeatured ? '#fffae5' : 'white', border: isFeatured ? '2px solid #ffc107' : 'none' }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>{senderName}</Typography>
                  <Typography variant="h6">{item?.subject}</Typography>
                  <Box sx={{ overflow: 'auto' }}>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </Box>
                  <Typography variant="caption" sx={{ color: 'gray', fontSize: '0.75rem' }}>
                    {displayDate}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  )
}

export default MessageList
