import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, TextField, FormControlLabel, Switch, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateOpenTimes } from 'api'; // Assuming you have an API utility

const daysOfWeek = [
    { day: 0, label: 'Monday' },
    { day: 1, label: 'Tuesday' },
    { day: 2, label: 'Wednesday' },
    { day: 3, label: 'Thursday' },
    { day: 4, label: 'Friday' },
    { day: 5, label: 'Saturday' },
    { day: 6, label: 'Sunday' },
];

const Opening = ({ venueData }) => {
    const { t } = useTranslation();
    const [openingTimes, setOpeningTimes] = useState([]);

    useEffect(() => {
        const initialTimes = daysOfWeek.map(({ day }) => {
            const existingTime = venueData.open_time.find(time => time.day === day);
            return existingTime || { day, time_start: '', time_end: '', status: 'Closed' };
        });

        // Only set openingTimes if the array is well-defined
        if (initialTimes.length) {
            setOpeningTimes(initialTimes);
        } else {
            console.warn("No opening times found for the venue data.");
            setOpeningTimes(daysOfWeek.map(({ day }) => ({ day, time_start: '', time_end: '', status: 'Closed' })));
        }
    }, [venueData]);

    const handleSwitchChange = (dayIndex) => {
        setOpeningTimes(prev => {
            const updatedTimes = [...prev];
            const currentStatus = updatedTimes[dayIndex].status === 'Closed' ? 'AC' : 'Closed';

            // Set default open and close times when opened
            if (currentStatus === 'AC') {
                updatedTimes[dayIndex] = {
                    ...updatedTimes[dayIndex],
                    status: currentStatus,
                    time_start: '09:00', // Default open time
                    time_end: '18:00',   // Default close time
                };
            } else {
                updatedTimes[dayIndex] = {
                    ...updatedTimes[dayIndex],
                    status: currentStatus,
                    time_start: '', // Reset times when closed
                    time_end: '',
                };
            }

            return updatedTimes;
        });
    };

    const handleTimeChange = (dayIndex, field, value) => {
        setOpeningTimes(prev => {
            const updatedTimes = [...prev];
            updatedTimes[dayIndex] = {
                ...updatedTimes[dayIndex],
                [field]: value,
                status: 'AC', // Set status to Active when time is set
            };
            return updatedTimes;
        });
    };

    const handleSave = async () => {
        const formattedData = openingTimes.map(({ day, time_start, time_end, status }) => ({
            day,
            time_start,
            time_end,
            status,
        }));

        try {
            const response = await updateOpenTimes({ slug: venueData.slug, openingTimes: formattedData });
            console.log('Opening times updated:', response.data);
        } catch (error) {
            console.error('Failed to update opening times:', error);
        }
    };

    return (
        <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
            {openingTimes.length > 0 && daysOfWeek?.map(({ day, label }, index) => {
                const { time_start, time_end, status } = openingTimes[index];

                return (
                    <Grid
                        item
                        xs={12}
                        key={day}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body1" sx={{ flex: '0 0 25%' }}>{label}</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={status === 'AC'}
                                    onChange={() => handleSwitchChange(index)}
                                    color="primary"
                                />
                            }
                            label={status === 'AC' ? 'Open' : 'Closed'}
                            sx={{ flex: '0 0 25%', margin: 0 }} // 25% for the switch
                        />
                        {status === 'AC' && (
                            <>
                                <TextField
                                    label="Open Time"
                                    type="time"
                                    value={time_start}
                                    onChange={(e) => handleTimeChange(index, 'time_start', e.target.value)}
                                    inputProps={{ step: 300 }} // 5 min
                                    sx={{ flex: '0 0 25%', marginLeft: 1 }} // 25% for open time
                                />
                                <TextField
                                    label="Close Time"
                                    type="time"
                                    value={time_end}
                                    onChange={(e) => handleTimeChange(index, 'time_end', e.target.value)}
                                    inputProps={{ step: 300 }} // 5 min
                                    sx={{ flex: '0 0 25%', marginLeft: 1 }} // 25% for close time
                                />
                            </>
                        )}
                    </Grid>
                );
            })}
            <Grid item xs={12}>
                <Box mt={4} display="flex" justifyContent="center">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {t('Save changes')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

Opening.propTypes = {
    venueData: PropTypes.shape({
        open_time: PropTypes.arrayOf(
            PropTypes.shape({
                day: PropTypes.number.isRequired,
                time_start: PropTypes.string,
                time_end: PropTypes.string,
                status: PropTypes.string,
            })
        ),
    }).isRequired,
};

export default Opening;
