import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import BookingHeader from '../bookingHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import BookingListItem from '../bookingListItem/view'
import { getCommunity, getBookings } from 'api'
import { Grid, Box, TextField, MenuItem, Button, Modal, Avatar, Drawer } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import dayjs, { Dayjs } from 'dayjs';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import noAvatar from '../../../../assets/images/no-avatar.png'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { useProfile } from 'context';
import { API_BASE_URL } from 'config/api';

const BookingHome = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { profile } = useProfile();
  const { slug } = params
  const [bookings, setBookings] = useState([])
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [filters, setFilters] = useState({
    status: 'AL',
    venue: '',
    code: '',
    date: '',
    order_by: 'planned_start_day',
    order_dir: 'desc',
    page_size: 10,
    page_number: 1
  });

  const typeMapping = {
    HD: 'Hot Desk',
    PR: 'Private Office',
    MR: 'Meeting Room',
    EV: 'Event',
    OT: 'Other'
  }

  const statusMapping = {
    AL: 'All',
    AC: 'Active',
    PP: 'Pending Payment',
    PC: 'Pending Confirmation',
    PN: 'Pending',
    PB: 'Pre-Booked',
    PR: 'Prepaid',
    CA: 'Cancelled',
    GR: 'Finished',
    RJ: 'Rejected'
  };

  const statusOptions = [
    { value: 'AL', label: 'All' },
    { value: 'AC', label: 'Active' },
    { value: 'PP', label: 'Pending Payment' },
    { value: 'PC', label: 'Pending Confirmation' },
    { value: 'PN', label: 'Pending' },
    { value: 'CA', label: 'Cancelled' },
    { value: 'GR', label: 'Finished' },
    { value: 'RJ', label: 'Rejected' }
  ];

  const getInitials = (name) => {
    if (!name) return '';
  
    const nameParts = name.trim().split(' ');
  
    if (nameParts.length === 1) {
      // If there's only one name part (no last name), use the first two letters of the first name
      return nameParts[0].substring(0, 2).toUpperCase();
    }
  
    // Otherwise, use the first letter of the first and last name
    const firstInitial = nameParts[0][0];
    const lastInitial = nameParts[1][0];
    
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getBookingsInfo(firstLoad) {
    getBookings(filters).then(r => {
      setBookings(r.bookings)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function clearFilters() {
    setFilters({
      status: 'ALL',
      venue: '',
      code: '',
      date: '',
      order_by: 'planned_start_day',
      order_dir: 'desc',
      page_size: 10,
      page_number: 1
    });
  }


  function navigateBooking(booking) {
    //navigate(`/bookings/${booking?.slug}`);
    setSelectedBooking(booking)
    setOpenDetails(true)
    //window.location.href = '/login'
  }

  function headerActionMethod() {
    navigate(`/booking-manage`);
  }

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
      page_number: 1
    });
  };

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page_number: pageNumber
    });
  };

  const handleSortChange = (column) => {
    const newOrderDir = filters.order_by === column && filters.order_dir === 'asc' ? 'desc' : 'asc';
    setFilters({
      ...filters,
      order_by: column,
      order_dir: newOrderDir,
      page_number: 1 // Reset page number when sorting changes
    });
  };

  useEffect(() => {
    getBookingsInfo(true)

  }, [filters])

  const roleMapping = {
    'FO': 'Founder',
    'AD': 'Admin',
    'MA': 'Manager',
    'PA': 'Participant',
    'GU': 'Guest',
  };

  const avatar = profile?.avatar ? `${API_BASE_URL}${profile?.avatar}` : noAvatar
  const background = profile?.background ? `${API_BASE_URL}${profile?.background}` : coworking


  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <InternalHeader title={'Your Bookings '} subtitle={''} avatar={avatar} background={background} />
      <IconButton
        onClick={() => setDrawerOpen(true)}
        sx={{ display: { xs: 'block', md: 'none' }, marginBottom: 2 }}
      >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ padding: 2, width: 250 }}>
          <IconButton onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
            <CloseIcon />
          </IconButton>
          <TextField
            label={t('Venue')}
            name="venue"
            value={filters.venue}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            select
            label={t('Status')}
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={t('Code')}
            name="code"
            value={filters.code}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label={t('Date')}
            name="date"
            type="date"
            value={filters.date}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            select
            label={t('Sort By')}
            name="order_by"
            value={filters.order_by}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="planned_start_day">{t('Day')}</MenuItem>
            <MenuItem value="venue__name">{t('Venue')}</MenuItem>
            <MenuItem value="status">{t('Status')}</MenuItem>
            <MenuItem value="code">{t('Code')}</MenuItem>
          </TextField>
          <Button variant="contained" color="secondary" onClick={clearFilters} fullWidth>
            {t('Clear Filters')}
          </Button>
        </Box>
      </Drawer>
      <Grid container spacing={2} sx={{ display: { xs: 'none', md: 'flex' }, padding: '25px' }}>
        <Grid item xs={2}>
          <TextField
            label={t('Venue')}
            name="venue"
            value={filters.venue}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            label={t('Status')}
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t('Code')}
            name="code"
            value={filters.code}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t('Date')}
            name="date"
            type="date"
            value={filters.date}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            label={t('Sort By')}
            name="order_by"
            value={filters.order_by}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="planned_start_day">{t('Day')}</MenuItem>
            <MenuItem value="venue__name">{t('Venue')}</MenuItem>
            <MenuItem value="status">{t('Status')}</MenuItem>
            <MenuItem value="code">{t('Code')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" fullWidth color="secondary" onClick={clearFilters} sx={{ marginLeft: '5px' }}>{t('Clear Filters')}</Button>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ padding: { xs: '12px', md: '25px' } }} >
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          {bookings?.length === 0 && (
            <NoResultsPlaceholder firstSentence={t('No bookings found')} lastSentence={t('Create a new booking now')} />
          )}
          {bookings?.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={3} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('venue__name')}><Typography variant="body1">{t('Venue')}
                  {filters.order_by === 'venue__name' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={3} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('planned_start_day')}><Typography variant="body1">{t('Date')}
                  {filters.order_by === 'planned_start_day' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={1} sx={{ cursor: 'pointer', display: { xs: 'none', md: 'block' } }} onClick={() => handleSortChange('price')}><Typography variant="body1">{t('Price')}
                  {filters.order_by === 'price' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={3} md={1} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('status')}><Typography variant="body1">{t('Status')}
                  {filters.order_by === 'status' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={3} md={1} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('code')}><Typography variant="body1">{t('Code')}
                  {filters.order_by === 'code' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}><Typography variant="body1">{t('Info')}</Typography></Grid>
              </Grid>
            </Box>
          )}
          {bookings?.map((item, index) => {
            const formattedStartTime = item?.planned_start_time ? dayjs(item.planned_start_time, 'HH:mm').format('HH:mm') : '';
            const formattedEndTime = item?.planned_end_time ? dayjs(item.planned_end_time, 'HH:mm').format('HH:mm') : '';
            const formattedDate = `${dayjs(item?.planned_start_day).format('DD/MM/YYYY')} ${formattedStartTime} - ${formattedEndTime}`;
            const price = (parseFloat(item.price) || 0) + (parseFloat(item.vat_amount) || 0);
            const formattedPrice = isNaN(price) ? '' : `£${price.toFixed(2)}`;
            return (
              <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                <Grid item xs={3} sx={{ cursor: 'pointer' }} onClick={() => navigateBooking(item)}><Typography variant="body2">{item?.venue?.name}</Typography></Grid>
                <Grid item xs={3}><Typography variant="body2">{formattedDate}</Typography></Grid>
                <Grid item xs={1}><Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>{formattedPrice}</Typography></Grid>
                <Grid item xs={3} md={1}><Typography variant="body2">{statusMapping[item?.status]}</Typography></Grid>
                <Grid item xs={3} md={1} ><Typography variant="body2">{item?.code}</Typography></Grid>
                <Grid item xs={3} sx={{ overflow: 'ellipsis', display: { xs: 'none', md: 'block' } }}><Typography variant="body2">{item?.plan_name}</Typography></Grid>
              </Grid>
            )
          })}
          {/* Pagination controls */}
          <Box sx={{ marginTop: 2, textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={filters.page_number === 1}
              onClick={() => handlePageChange(filters.page_number - 1)}
              sx={{ marginRight: 1 }}
            >
              {t('Previous Page')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={bookings.length < filters.page_size}
              onClick={() => handlePageChange(filters.page_number + 1)}
            >
              {t('Next Page')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalContent>
          <div>
            <h2>Booking Details</h2>
            <p><b>Venue:</b> {selectedBooking?.venue?.name}</p>
            <p><b>Date:</b> {selectedBooking?.planned_start_day}</p>
            <p><b>Time:</b> {selectedBooking?.planned_start_time} - {selectedBooking?.planned_end_time}</p>
            <p><b>Price:</b> £{selectedBooking?.price}</p>
            <p><b>Type:</b> {typeMapping[selectedBooking?.booking_type]}</p>
            <p><b>Status:</b> {statusMapping[selectedBooking?.status]}</p>
            <p><b>Code:</b> {selectedBooking?.code}</p>
            <p><b>Description:</b> {selectedBooking?.plan_name}</p>
            <p><b>Who is coming:</b></p>
            {/* Add more details as needed */}
            {/* Participants Row */}
            <Grid container spacing={2}>
              {selectedBooking?.related_bookings?.map((participant, index) => (
                <Grid item key={index}>
                  <Avatar
                    src={participant.participant_image}
                    alt={participant.participant_name}
                  >
                    {!participant.participant_image && getInitials(participant.participant_name)}
                  </Avatar>
                </Grid>
              ))}
            </Grid>
          </div>

        </ModalContent>
      </Modal>
      {/* List of communities */}
    </Container>
  )
}

BookingHome.propTypes = {}

export default BookingHome
