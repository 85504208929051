import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router'
import Account from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import { useProfile } from 'context';
import { useAuth } from 'hooks/useAuth';

const isAuthenticated = localStorage.getItem('token') != null

//const pages = ['Dashboard', 'Community', 'Events', 'Bookings', 'Spaces' ];


function MainNavBar() {

  const { profile, setProfile } = useProfile();
  const { doLogout } = useAuth();

  const commonPages = ['Home', 'Community', 'Spaces',];
  const staffPages = ['Spaces'];
  const adminPages = ['Home', 'Community', 'Book', 'Events', 'Spaces'];

  const pages = profile?.staff ? staffPages : commonPages;
  let settings = profile ? ['Bookings', 'Profile', 'Logout'] : ['Signup'];

  if (profile && profile?.staff) {
    settings = ['Profile', 'Logout'];
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function navigateMenu(target) {
    if (target === 'Home') {
      if (profile?.staff == true) {
        navigate(`/manage/venues`);
      } else {
        navigate(`/dashboard`);
      }
    }
    if (target === 'Community') {
      navigate(`/communities`);
    }
    if (target === 'Events') {
      navigate(`/events`);
    }
    if (target === 'Spaces') {
      if (profile?.staff == true) {
        console.log('staff, navigating manage')
        navigate(`/manage/venues`);
      } else {
        console.log('not staff, navigating venues')
        navigate(`/venues`);
      }
    }
    if (target === 'Book') {
      navigate('/booking-find');
    }


    //window.location.href = '/login'
  }

  function navigateSetting(setting) {
    if (setting === 'Profile') {
      navigate(`/profile`);
    }
    if (setting === 'Account') {
      navigate(`/account`);
    }
    if (setting === 'Bookings') {
      navigate(`/bookings`);
    }
    if (setting === 'Signup') {
      navigate(`/signup`);
    }
    if (setting === 'Manage') {
      navigate(`/manage/venues`);
    }
    if (setting === 'Logout') {
      doLogout()
    }
  }

  return (
    <AppBar position="static" style={{ backgroundColor: '#0F617D' }}>
      <Container maxWidth="xl" style={{ backgroundColor: '#0F617D' }}>
        <Toolbar disableGutters style={{ backgroundColor: '#0F617D' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigateMenu('Home')}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            HIVESPACE
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: '#fff' }} // Icon color set to white
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none', color: 'white' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => { navigateMenu(page) }}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            //href="#app-bar-with-responsive-menu"
            onClick={() => navigateMenu('Home')}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HIVESPACE
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => navigateMenu(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ fontSize: '0.875rem', color: '#fff' }}>
              Hello, {profile?.first_name}
            </Typography>
            {/* Messaging Icon Button */}
            <Tooltip title="Messages">
              <IconButton onClick={() => navigate('/messages')}>
                <Badge badgeContent={1} color="error">
                  <MailIcon sx={{ color: '#fff' }} />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Account sx={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => navigateSetting(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MainNavBar;