import React, { useEffect, useState } from 'react'
import bgReset from 'assets/images/reset-success.jpg'
import { useTranslation } from 'react-i18next'
import { Container, Header, Subtitle, Title, Wrapper } from './styled'
import { confirmPassword } from 'api'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import PassInput from 'components/atoms/passInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validate from './validate'

const ResetConfirm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { slug } = params
  const [open, setOpen] = useState(false)
  const [resetStatus, setResetStatus] = useState(0)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, // You can use this if you need to set the value of the form
  } = useForm({
    resolver: yupResolver(validate),
    defaultValues: { password: '' }
  })

  const handleClose = () => {
    setOpen(false)
    navigate('/login')
  }

  const verifyReset = async (data) => {
    console.log('Verify reset data:', data)
    const postData = { password: data.password, slug }
    console.log('Post data:', postData)
    try {
      console.log('confirm password:', postData)
      await confirmPassword(postData)
      setOpen(true)
      setResetStatus(1)
    } catch (error) {
      console.error('Error confirming password:', error);
      setOpen(true)
      setResetStatus(-1)
    }
  }

  const handleVerifyReset = handleSubmit((data) => {
    console.log('Data:', data);
    verifyReset(data);
  });

  return (
    <Container style={{ backgroundImage: `url(${bgReset})` }}>
      <Wrapper elevation={0}>
        <Header>
          <Title variant='h4'>{t('reset.title')}</Title>
          <Subtitle>{t('reset.confirm')}</Subtitle>
        </Header>
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', width: '100%' }}>
              <PassInput
                label={t('login.passwordLabel')}
                name='password'
                control={control}
                error={errors.password}
                sx={{ width: '100%', display: 'flex' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
              <Button variant='contained' color='secondary' onClick={() => handleVerifyReset()} fullWidth>
                {t('reset.update')}
              </Button>
            </Grid>
          </Grid>

      </Wrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Password reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resetStatus === 1 ? "Your password has been reset successfully." : "Password reset failed. Please try again."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default ResetConfirm
