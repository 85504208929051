import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalDialog from 'components/molecules/modalDialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PassInput from 'components/atoms/passInput'
import TextInput from 'components/atoms/textInput'
import { Container, ForgotButton } from './styled'
import validateEmail from './validateEmail'
import {resetPassword} from 'api'
import { useSnackbar } from 'notistack'

const ResetFormView = ({ onSubmit, onSuccess, setShowReset }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateEmail),
  })

  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')

  const handleClickForgot = () => {
    console.log('Forgot password clicked')
    
  }

  const handleClose = () => {
    setOpen(false);
    navigate('/login')
  };

    // Modify the onSubmit function to trigger the dialog
    const handleFormSubmit = async (data) => {
      const response = await resetPassword(data);
      console.log(response)
      if (response?.message === 'email_not_found') {
        enqueueSnackbar(('Email address not found'), {
          variant: 'error',
        })
        
      }
      else if (response?.message === 'email_sent') {
        enqueueSnackbar(t('reset.success'), {
          variant: 'success',
        })
        setOpen(true);
        //onSuccess(result)
      }
      else {
        enqueueSnackbar(('Email address not found'), {
          variant: 'error',
        })
      }

    }

  return (
    <>
      <Container >
        <TextInput label={t('login.emailLabel')} name='email' control={control} error={errors?.email}/> 
        <ForgotButton onClick={() => handleClose()}>{t('reset.backLogin')}</ForgotButton>
        <Button variant='contained' color='secondary' onClick={handleSubmit(handleFormSubmit)}>
          {t('reset.reset')}
        </Button>
      </Container>      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Password reset"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We have sent an email to your account to reset your password. You will now be redirected to the login page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ResetFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ResetFormView
