import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Badge } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

// General Container Styles
export const Container = styled('div')`
  min-height: 100vh;
`;

// Styled Components for Calendar
export const StyledBadge = styled(Badge)(({ theme }) => ({
  '.MuiBadge-dot': {
    backgroundColor: 'transparent', // Remove the default dot if needed
  },
  '.MuiBadge-badge': {
    top: 5, // Adjust vertical positioning
    right: 8, // Adjust horizontal positioning
    backgroundColor: 'transparent', // Badge background color
    color: theme.palette.secondary.main, // Badge text color
    fontSize: '0.86rem', // Font size for price
    height: '1.5rem', // Adjust badge height
    width: '1.5rem', // Adjust badge width
    borderRadius: '50%', // Make badge circular
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  fontSize: '1.25rem', // Increase font size for days
}));

// Layout and Content Styles
export const SecondRowContainer = styled(Box)({
  maxWidth: '80vw', // Default width for desktop
  margin: '0 auto', // Center the container on desktop
  '@media (max-width: 900px)': {
    maxWidth: '100%', // Set width to 100% for smaller screens
  },
});

export const ModalContent = styled('div')`
  background-color: #fff;
  max-height: 70vh;
  overflow-y: auto; // Enable vertical scrolling when content overflows
  width: 30%; // Full screen on mobile devices
  margin: 0 auto; // Center horizontally
  justify-content: center; // Center horizontally
  margin-top: 10vh;
  padding: 20px 20px 30px;
  margin-bottom: 15vh;

  @media (max-width: 768px) {
    width: 90%; // Full screen on mobile devices
    height: 80vh;
    margin: 10vh auto; // Center horizontally
  }
`;

export const OverviewLeft = styled('div')`
  @media (max-width: 768px) {    
    margin: 0 5vw; // Center horizontally
  }
`;

export const OverviewRight = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  align-items: initial !important;

  @media (max-width: 768px) {    
    margin: 0 5vw; // Center horizontally
  }
`;

export const OverviewMainTitle = styled('div')`
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin-top: 25px;
`;

export const OverviewMainSubtitle = styled('div')`
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
`;

export const OverviewTitle = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,  
  marginBottom: '15px',
  marginTop: '40px',
  backgroundColor: theme.palette.secondary.main, // Badge text color
  color: theme.palette.box_title.main, // Badge text color
  padding: '10px',
}));

export const OfferContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Corrected from 'top' to 'flex-start'
  align-items: flex-start; // Corrected from 'top' to 'flex-start'
  background-color: #fff;
  margin-bottom: 20px;
`;

export const LocationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Corrected from 'top' to 'flex-start'
  align-items: flex-start; // Corrected from 'top' to 'flex-start'
  background-color: #fff;
  margin-bottom: 20px;
`;

export const OpeningTimesContainer = styled('div')`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 0.8rem;
`;

export const InfoTextSmall = styled('div')`
    align-items: start;
    font-size: 14px;
`

export const InfoTextMedium = styled('div')`
    font-size: 16px;
`

export const HeroBoxMain = styled('div')`
    width: 100%;    
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`

export const HeroAvatarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 150px;
  margin: 5px;
`;

export const AvatarWrapper = styled('div')`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

export const HeroPriceContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
  font-size: 20px;
  font-weight: 700;
`;

export const HeroMainData = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const HeroMainText = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    height: 100%;
`


export const ResponsiveTextField = styled('div')`
  width: 100%; /* Full width on mobile by default */

  @media (min-width: 600px) {
    width: 20%; /* 20% width on tablets and above */
  }
`;
