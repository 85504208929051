import React, { useState, useEffect } from 'react'
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Input,
} from '@mui/material'

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg'
import { convertToHTML } from 'draft-convert'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { updateVenue, getVenueStaff, uploadVenueImage } from 'api' // Assuming you have an API utility
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'

const GeneralInfoForm = ({ venueData }) => {
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [access, setAccess] = useState("")
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [background, setBackground] = useState(null);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    full_description: EditorState.createEmpty(),
    single_address: '',
    website: '',
    contact_email: '',
    contact_phone_1: '',
    contact_phone_2: '',
    access: EditorState.createEmpty(),
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    linkedin_url: '',
    occupancy_instant_booking: 0,
  })
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [descriptionEditorState, setDescriptionEditorState] = useState();
  const [accessEditorState, setAccessEditorState] = useState();

  // Fetch venue data on component mount

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleUploadBackground = () => {
    console.log('Uploaidng background')
    uploadVenueImage(venueData?.slug, selectedBackground, 'background').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleUploadImage = () => {
    uploadVenueImage(venueData?.slug, selectedImage, 'image').then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  // Handle WYSIWYG editor state change
  const handleEditorStateChange = (editorState) => {
    console.log('Editor state change', editorState)
    if (editorState) {
      setDescriptionEditorState(editorState);
      setDescription(
        convertToRaw(editorState?.getCurrentContent())
      );
    }
  }

  const handleAccessEditorStateChange = (editorState) => {
    console.log('Access editor state change', editorState)
    if (editorState) {
      setAccessEditorState(editorState);
      setAccess(
        convertToRaw(editorState?.getCurrentContent())
      );
    }
  }

  const handleBackgroundChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        /*setSelectedBackground({
          file: file,
          imageUrl: event.target.result,
        });*/
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackground(reader.result);
      };
    }
    setSelectedBackground(file);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        /*setSelectedImage({
          file: file,
          imageUrl: event.target.result,
        });*/
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
    setSelectedImage(file);
  };

  // Submit form data to the API
  const handleSubmit = async () => {
    setLoading(true)
    setError('')

    const htmlDescription = descriptionEditorState ? convertToHTML(descriptionEditorState.getCurrentContent()) : '';
    const htmlAccess = accessEditorState ? convertToHTML(accessEditorState.getCurrentContent()) : '';
    // Prepare the data
    const updateData = {
      slug: venueData?.slug,
      name: formData.name,
      description: formData.description,
      full_description: htmlDescription,
      image: formData.image,
      thumbnail: formData.thumbnail,
      single_address: formData.single_address,
      website: formData.website,
      contact_email: formData.contact_email,
      contact_phone_1: formData.contact_phone_1,
      contact_phone_2: formData.contact_phone_2,
      access: htmlAccess,
      twitter_url: formData.twitter_url,
      facebook_url: formData.facebook_url,
      instagram_url: formData.instagram_url,
      linkedin_url: formData.linkedin_url,
      occupancy_instant_booking: formData.occupancy_instant_booking
    }

    try {
      const response = await updateVenue(updateData) // Call the API
      console.log('General info response ', response)

    } catch (err) {
      console.error(err)
      setError('An error occurred while updating the venue.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedBackground !== null) {
      handleUploadBackground()
    }
  }, [selectedBackground])

  useEffect(() => {
    if (selectedImage !== null) {
      handleUploadImage()
    }
  }, [selectedImage])

  useEffect(() => {
    console.log(venueData)
    setFormData({
      name: venueData.name || '',
      description: venueData.description || '',
      single_address: venueData.single_address || '',
      website: venueData.website || '',
      contact_email: venueData.contact_email || '',
      contact_phone_1: venueData.contact_phone_1 || '',
      contact_phone_2: venueData.contact_phone_2 || '',
      access: venueData.access || '',
      twitter_url: venueData.twitter_url || '',
      facebook_url: venueData.facebook_url || '',
      instagram_url: venueData.instagram_url || '',
      linkedin_url: venueData.linkedin_url || '',
      occupancy_instant_booking: venueData.occupancy_instant_booking || 0,
    })
    if (venueData.full_description) {
      const blocksFromHTML = convertFromHTML(venueData.full_description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      const editorDescriptonState = EditorState.createWithContent(state);
      setDescriptionEditorState(editorDescriptonState)
    }
    if (venueData.access) {
      const blocksFromHTML = convertFromHTML(venueData.access);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      const editorAccessState = EditorState.createWithContent(state);
      setAccessEditorState(editorAccessState)
    }
  }, [venueData])

  return (
    <Container maxWidth="md">
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {/* Name Field */}
      <TextField
        name="name"
        label="Venue Name"
        value={formData.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Short Description Field */}
      <TextField
        name="description"
        label="Short Description"
        value={formData.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        inputProps={{ maxLength: 100 }}
      />

      {/* Full Description Field (HTML Editor) */}
      <Box mt={3} mb={3}>
        <Typography>Full Description</Typography>
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '10px',
            minHeight: '200px',
          }}
        >
          <Editor
            editorState={descriptionEditorState}
            onEditorStateChange={handleEditorStateChange}
            wrapperClassName="wysiwyg-wrapper"
            editorClassName="wysiwyg-editor"
          />
        </Box>
      </Box>

      {/* Single Address Field */}
      <TextField
        name="single_address"
        label="Single Address"
        value={formData.single_address}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Website Field */}
      <TextField
        name="website"
        label="Website"
        value={formData.website}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Contact Email Field */}
      <TextField
        name="contact_email"
        label="Contact Email"
        value={formData.contact_email}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Contact Phone 1 Field */}
      <TextField
        name="contact_phone_1"
        label="Contact Phone 1"
        value={formData.contact_phone_1}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Contact Phone 2 Field */}
      <TextField
        name="contact_phone_2"
        label="Contact Phone 2"
        value={formData.contact_phone_2}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <Box mt={3} mb={3}>
        <Typography>Access instructions</Typography>
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '10px',
            minHeight: '200px',
          }}
        >
          <Editor
            editorState={accessEditorState}
            onEditorStateChange={handleAccessEditorStateChange}
            wrapperClassName="wysiwyg-wrapper"
            editorClassName="wysiwyg-editor"
          />
        </Box>
      </Box>

      {/* Twitter URL Field */}
      <TextField
        name="twitter_url"
        label="X URL"
        value={formData.twitter_url}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Facebook URL Field */}
      <TextField
        name="facebook_url"
        label="Facebook URL"
        value={formData.facebook_url}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Instagram URL Field */}
      <TextField
        name="instagram_url"
        label="Instagram URL"
        value={formData.instagram_url}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* LinkedIn URL Field */}
      <TextField
        name="linkedin_url"
        label="LinkedIn URL"
        value={formData.linkedin_url}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Occupancy Instant Booking Field */}
      <TextField
        name="occupancy_instant_booking"
        label="Occupancy Instant Booking"
        value={formData.occupancy_instant_booking}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      {/* Image Uploads */}
      <Box mt={2}>
        <Typography>Image</Typography>
        <Input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          fullWidth
        />
      </Box>

      <Box mt={2}>
        <Typography>Background Image</Typography>
        <Input
          type="file"
          name="background"
          accept="image/*"
          onChange={handleBackgroundChange}
          fullWidth
        />
      </Box>

      {/* Submit Button */}
      <Box mt={4} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {t('Save changes')}
        </Button>
      </Box>

    </Container>
  )
}

GeneralInfoForm.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default GeneralInfoForm
