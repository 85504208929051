import styled from 'styled-components'
import { Avatar, ListItem } from '@mui/material';

export const Container = styled.div`
    margin: 20px;  
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  

    }
`

export const AvatarContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Add this for vertical centering
    marginTop: '20px',
});

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: '120px',
    height: '120px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
        width: '180px',
        height: '180px',
    },
    // More specificity if necessary:
    '&.MuiAvatar-root': {
        width: '120px',
        height: '120px',
    }
}));


export const CommunityBasic = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
  });

  export const CommunityName = styled('div')({
    fontSize: '18px', // Slightly larger font size
    fontWeight: 'bold', // Make the text bold
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px 0',
    marginTop: '10px',
    marginLeft: '3px',
    marginRight: '3px',
    borderRadius: '8px', // Rounded corners
    background: 'linear-gradient(to right, rgb(15, 97, 125), #0d6d8b)', // Gradient with primary color
    color: '#fff', // Contrasting text color
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
});


export const CommunityStatus = styled('div')({
    fontSize: '16px',
    marginBottom: '10px',
});
export const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.selected : 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    // Add the following styles to replace the deprecated method
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.action.hover,
    },
}));