import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { Button, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container, HomeContent, SectionHead, HeroBoxSubtitle } from './styled'
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Card, CardMedia, CardContent, Link, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderToolbar from '../../../../components/organisms/headerToolbar'
import HomeHeader from '../homeHeader/view'
import ProfileSingle from 'components/organisms/account/ProfileSingle'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import coworking from 'assets/images/coworking.jpg'
import home_community from 'assets/images/home_community.jpg'
import home_events from 'assets/images/home_events.jpg'
import home_spaces from 'assets/images/home_spaces.jpg'
import parallax from 'assets/images/parallax.jpg'
import about_us from 'assets/images/about_us.jpg'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import PublicNavBar from 'components/organisms/publicNavBar/view'

const RigthActions = ({ pathname, t, reloadData }) => {
  const [modalStaff, setModalStaff] = useState(false)
  const [modalMSPlan, setModalMSPlan] = useState(false)

  function handleStaffClose() {
    setModalStaff(false)
    reloadData(true)
  }

  function handleMSClose() {
    setModalMSPlan(false)
  }

}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  //handleClickOnLink: PropTypes.func.isRequired,
}

const HomeView = ({ permitedUrls, ...props }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [reloadData, setReloadData] = useState(false)

  // tabs
  const [staffData, setStaffData] = useState(null)
  const permited = []

  const spaces = [
    {
      name: 'Space 1',
      address: 'Address 1',
      image: coworking,
    },
    {
      name: 'Space 2',
      address: 'Address 2',
      image: coworking,
    },
  ]

  const faqItems = [
    {
      question: 'What is HiveSpace?',
      answer: 'HiveSpace is a platform that seamlessly integrates vibrant communities, genuine connections, and collaborative environments. It allows you to create and manage communities, host events, and unlock unique working spaces.'
    },
    {
      question: 'How can I book a space?',
      answer: 'We are still on Beta testing, but we have a bunch of awesome places in London where you can meet our community. Please get in touch ar hello@the-epic.space!'
    },
    {
      question: 'How can I create a community?',
      answer: 'To create a community, sign in to your account, go to the dashboard, and follow the steps to create a new community. Define your purpose, set your goals, and watch your community grow.'
    },
    // Add more FAQ items as needed
  ];  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const title = 'Discover, Connect, Thrive: HiveSpace'

  return (
    <Container>
      <PublicNavBar />
      <HomeHeader title={title}></HomeHeader>
      <HomeContent>
        <Box sx={{ padding: '80px 20px' }}>
          <Grid container spacing={4} sx={{ marginBottom: '10vh' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                About Us
              </Typography>
              <Typography variant="body1" paragraph>
                Welcome to <b>HiveSpace</b>, where community building meets real-world experiences. Our mission is to empower individuals and organisations to create vibrant communities and memorable (Epic) events in the heart of London.
              </Typography>
              <Typography variant="body1" paragraph>
                At HiveSpace, we believe in the power of face-to-face connections. Our platform is designed to bring people together, fostering communities that thrive on shared interests and experiences
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* You can add an image or illustration here */}
              <Box sx={{ width: '100%', height: '100%' }}>
                {/* Replace 'aboutUsImage.jpg' with your actual image file */}
                <img
                  src={about_us}
                  alt="About Us"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>

              <ParallaxBanner
                layers={[
                  { image: parallax, speed: -20 },
                  {
                    speed: -25,
                    children: (
                      <div>
                        <Typography variant="h4" gutterBottom sx={{
                          fontSize: '4.5rem',
                          textAlign: 'left',
                          marginTop: '40vh',
                          padding: '20px',
                          color: '#fff', // Set text color to white or your preferred color
                          '@media (max-width: 600px)': {
                            fontSize: '2rem',
                          }
                        }}>
                          Productivity Meets Community
                        </Typography>
                      </div>
                    ),
                  },
                ]}
                className="aspect-[2/1]"
                style={{ aspectRatio: '2 / 1', marginTop: '20px' }}
              >

              </ParallaxBanner>





            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SectionHead>
                Our platform seamlessly integrates vibrant communities, genuine connections, and collaborative environments. Whether creating communities, hosting events, or unlocking unique working spaces, HiveSpace is your gateway to a transformative experience.
              </SectionHead>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                Communities
              </Typography>
              <Typography variant="body1" paragraph>
                Whether you're passionate about a particular interest or looking to connect with like-minded individuals, HiveSpace is your platform. Start by creating your own community in just a few simple steps. Define your purpose, set your goals, and watch your community grow. Alternatively, explore existing communities and join the ones that align with your interests. HiveSpace provides the tools you need to connect with a diverse network of individuals who share your passions.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingLeft: 0 }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                {/* Replace 'aboutUsImage.jpg' with your actual image file */}
                <img
                  src={home_community}
                  alt="About Us Image"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingLeft: 0 }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                {/* Replace 'aboutUsImage.jpg' with your actual image file */}
                <img
                  src={home_events}
                  alt="About Us Image"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                Events
              </Typography>
              <Typography variant="body1" paragraph>
                Planning an event? HiveSpace makes it easy. Whether you have a specific event in mind or are looking for exciting happenings to attend, our platform has you covered. Seamlessly organize, promote, and host your own events, ranging from intimate gatherings to large-scale happenings. In addition, immerse yourself in the community by exploring and joining events created by others. Discover a world of diverse experiences and connect with like-minded individuals who share your interests.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} >
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                Working Spaces
              </Typography>
              <Typography variant="body1" paragraph>
                Elevate your community experience with exclusive working spaces. HiveSpace offers special rates for community members, providing flexible and inspiring environments where collaboration thrives. Imagine spending your workday alongside individuals who are attending the same event as you later. Whether you're a group of professionals, sports enthusiasts, or hobbyists, find or create a unique working space tailored to your interests. HiveSpace empowers you to organise and attend meaningful experiences with like-minded individuals, making every moment count.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingLeft: 0 }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                {/* Replace 'aboutUsImage.jpg' with your actual image file */}
                <img
                  src={home_spaces}
                  alt="About Us Image"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4} sx={{ marginTop: '5vh' }}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom sx={{
                textAlign: 'left',
                color: 'title.main',
              }}>
                FAQ
              </Typography>
              {faqItems.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{item.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>

        </Box>
      </HomeContent>

      {/* 
      <div>
        <Typography variant="h4" gutterBottom sx={{textAlign:'center', paddingBottom:'5vh'}}>
          Our community
        </Typography>
        <Slider {...settings}>
          {spaces.map((space, index) => (
            <Card key={index} sx={{ maxWidth: 300 }}>
              <CardMedia
                component="img"
                height="140"
                image={space.image} // Replace with the actual property holding the image URL
                alt={space.name}
              />
              <CardContent>
                <Typography variant="h6">{space.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {space.address}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Slider>
        <Button variant="contained" color="primary" sx={{ marginTop: 2, marginBottom: 10 }}>
          Discover More
        </Button>
      </div> */}


      <Box
        component="footer"
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          marginTop: 'auto', // Push the footer to the bottom
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: hello@the-epic.space
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Link href="/privacy-policy" color="text.primary" sx={{ mr: 2 }}>
            Privacy Policy
          </Link>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} HiveSpace Ltd. All rights reserved.
          </Typography>
          <a href="https://www.vecteezy.com/free-vector/community">Community Vectors by Vecteezy</a>
        </Box>
      </Box>
    </Container >
  )
}

HomeView.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  permitedUrls: PropTypes.object,
}

HomeView.defaultProps = {
  permitedUrls: {},
}

export default HomeView
