import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container, AvatarContainer, SliderBlock } from './styled'
import { FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import { joinEvent, updateEventTicket, uploadEventTicketAvatar, validateTicket, joinCommunityGuest } from 'api'
import { CircularProgress, Grid, Modal, Button, Stepper, Step, StepLabel, Avatar } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Alert, AlertTitle, IconButton, Collapse, Chip, Slider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar-upload.png'

const EventTicketUpdate = ({ visibleModal, myTicket, event, reload }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams();
  const { slug, key } = params
  const [validation, setValidation] = useState(!myTicket?.user?.is_active || false)
  const [member, setMember] = useState(!event?.membership || event?.membership?.role === 'GU')
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const steps = ['About you', 'Additional info'];
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedNeeds, setSelectedNeeds] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [needs,] = useState(event?.tags || []);
  const [offers,] = useState(event?.tags || []);
  const [validationValue, ] = useState(searchParams.get('key') || null);
  const [avatar, setAvatar] = useState(myTicket?.user?.avatar ? `${API_BASE_URL}${myTicket?.user?.avatar}` : noAvatar);

  
  const options = ['Option 1', 'Option 2', 'Option 3'];
  const valueTextMap = {
    0: 'Learn',
    1: 'None',
    2: 'Share',
  };


  const [ticketData, setTicketData] = useState({
    link_1: myTicket?.link_1,
    link_2: myTicket?.link_2,
    link_3: myTicket?.link_3,
    info: myTicket?.user?.alias,
    name: myTicket?.user?.first_name + ' ' + myTicket?.user?.last_name,
    description: myTicket?.user?.description,
    social: myTicket?.social,
    needs: myTicket?.needs ? myTicket?.needs : [],
    offers: myTicket?.offers ? myTicket?.offers : [],
  })

  const checkValidation = () => {
    if (validationValue !== null) {
      const data = {
        key: validationValue
      }
      validateTicket(myTicket?.slug, data).then(r => {
        setValidation(false)
    })
      .catch(error => console.log('Update event error -> ', error))
    }
  }

  const handleNext = () => {
    updateEventTicket(myTicket?.slug, ticketData).then(r => {
      reload()
    })
      .catch(error => console.log('Update event error -> ', error))


    if ((activeStep) === steps.length) {
      visibleModal(false)
      setActiveStep(1)
    } else {
      setActiveStep((prevStep) => prevStep + 1)
    }

  }

  const joinTheCommunity = () => {
    const data = {
      ticket: myTicket.slug,
    }
    joinCommunityGuest(data).then(r => {
      reload()
      setMember(false)
    })
      .catch(error => console.log('Update event error -> ', error))

  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  

  const handleUpload = () => {
    uploadEventTicketAvatar(myTicket.slug, selectedFile).then(r => console.log(r))
      .then((response) => {
        if (response && response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  const handleSliderChange = (tag, value) => {
    let needs = ticketData?.needs?.filter((item) => item !== tag)
    let offers = ticketData?.offers?.filter((item) => item !== tag)
    if (value === 0) {
      needs = [...needs, tag]
      // Add the tag to 'needs'
    } else if (value === 2) {
      
      // Add the tag to 'offers'
      offers = [...offers, tag]
    }
    setTicketData((prevData) => ({
      ...prevData,
      needs: needs,
      offers: offers,
    }));
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleInputOptionsChange = (event) => {
    const { name, value } = event.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      
      reader.onload = (event) => {
        setSelectedFile({
          file: file,
          imageUrl: event.target.result,
        });
      };      
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
    }
    setSelectedFile(file);
  };

  const openFileInput = () => {
    document.getElementById('avatarInput').click();
  };

  function valuetext(value) {
    return valueTextMap[value];
  }


  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload()
    }
  }, [selectedFile])

  useEffect(() => {
    if (validationValue !== null) {
      checkValidation()
    }
  }, [])

  return (
    <Container>
      <div>
        <div>
          <form>
            {activeStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="avatarInput"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <AvatarContainer>
                    <Avatar
                      src={avatar}
                      alt="Avatar"
                      style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '50%' }}
                      onClick={openFileInput}
                    />
                    <Typography color="textSecondary" style={{ marginTop: '3px', fontSize:'12px' }}>
                      Update
                    </Typography>
                  </AvatarContainer>
                </Grid>
                <Grid item xs={9}>
                <FormItemColumn>
                    <Typography variant="body2" color="textSecondary">
                      Your name
                    </Typography>
                    <TextField
                      size='small'
                      variant="outlined"
                      required
                      fullWidth
                      name="name"
                      value={ticketData.name}
                      onChange={handleInputOptionsChange}
                    />
                  </FormItemColumn>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Collapse in={member}>
                    <Alert
                      severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setMember(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      <AlertTitle>Join {event.group?.name}?</AlertTitle>
                      Join our commnity and get access to all our events and resources. Get in touch with all members and take advantage of promotions and discounts. 
                      <Button
                        variant="contained"
                        color="button_color"
                        onClick={() => joinTheCommunity()}
                        fullWidth
                        style={{ marginTop: '10px' }}
                      >Join
                      </Button>
                    </Alert>
                  </Collapse>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Collapse in={validation}>
                    <Alert
                      severity="warning"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setValidation(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      <AlertTitle>Email validation pending</AlertTitle>
                      You should have an email from our partner <strong>HiveSpace</strong>. Can you please check your inbox and follow the instructions on it? You may have received it in your spam folder. 
                    </Alert>
                  </Collapse>
                </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormItemColumn>
                    <Typography variant="body2" color="textSecondary">
                      Can you quickly describe your job, role or ambitions (CTO, UX designer, Student...)?
                    </Typography>
                    <TextField
                      size='small'
                      variant="outlined"
                      required
                      fullWidth
                      name="info"
                      value={ticketData.info}
                      onChange={handleInputOptionsChange}
                    />
                  </FormItemColumn>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Here you can add any field that you would like to learn about or share your knowledge with the community.
                  </Typography>
                  {
                    event.tags?.map((tag, index) => {
                      let defaultValue = 1; // Default value 'None'
                      if (ticketData.needs.includes(tag.name)) {
                        defaultValue = 0; // 'Learn'
                      } else if (ticketData.offers.includes(tag.name)) {
                        defaultValue = 2; // 'Share'
                      }
                      return <SliderBlock key={index}>
                        <Typography style={{ textAlign: 'center', fontSize: '18px', paddingTop: '10px' }}>{tag.name}</Typography>
                        <Slider                          
                          aria-label={tag.name}
                          defaultValue={defaultValue}
                          getAriaValueText={valuetext}
                          valueLabelDisplay="auto"
                          color='button_color'
                          step={1}
                          marks={[
                            { value: 0, label: 'Learn' },
                            { value: 1, label: 'None' },
                            { value: 2, label: 'Share' },
                          ]}
                          min={0}
                          max={2}
                          onChange={(event, value) => handleSliderChange(tag.name, value)}
                        />
                      </SliderBlock>
                    })
                  }
                </Grid>



                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="button_color"
                      onClick={() => handleNext(false)}
                      fullWidth
                    >Next
                    </Button>
                  </Grid>

              </Grid>

            )}
            {activeStep === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormItemColumn>
                    <Typography variant="body2" color="textSecondary">
                      Are you happy to be contacted? Please share here your LinkedIn, Personal Website, Calendly, etc.
                    </Typography>
                    <TextField
                      size='small'
                      variant="outlined"
                      label="Linkedin, Website, Calendly, etc."
                      required
                      fullWidth
                      name="link_1"
                      style={{ marginTop: '15px' }}
                      value={ticketData.link_1}
                      onChange={handleInputOptionsChange}
                    />
                  </FormItemColumn>
                </Grid>
                <Grid item xs={12}>
                  <FormItemColumn>
                    <Typography variant="body2" color="textSecondary">
                      Any other website or project you would like to share? Please add your link here.
                    </Typography>
                    <TextField
                      size='small'
                      variant="outlined"
                      required
                      fullWidth
                      label="Anything to share?"
                      name="link_2"
                      style={{ marginTop: '15px' }}
                      value={ticketData.link_2}
                      onChange={handleInputOptionsChange}
                    />
                  </FormItemColumn>
                </Grid>
                <Grid item xs={12}>
                  <FormItemColumn>
                    <Typography variant="body2" color="textSecondary">
                      Any short description that you would like to share with the community?
                    </Typography>
                    <TextField
                      size='small'
                      variant="outlined"
                      fullWidth
                      multiline rows={4}
                      name="description"
                      style={{ marginTop: '15px' }}
                      value={ticketData.description}
                      onChange={handleInputOptionsChange}
                    />
                  </FormItemColumn>
                </Grid>

                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="button_color"
                      fullWidth
                      onClick={() => handleBack(true)}
                    >Prev
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="button_color"
                      fullWidth
                      onClick={() => handleNext(false)}
                    >Finish
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </form>
        </div>

      </div>

    </Container>
  )
}

EventTicketUpdate.propTypes = {
  visibleModal: PropTypes.func,
  myTicket: PropTypes.object,
  event: PropTypes.object,
  reload: PropTypes.func,
}
EventTicketUpdate.defaultProps = {
  visibleModal: () => null,
  myTicket: {},
  event: {},
  reload: () => null,
}


export default EventTicketUpdate
