import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'

import {
  Container,
  SecondRowContainer,
  ModalContent,
  OverviewRight,
  OverviewLeft,
  OverviewTitle,
  OpeningTimesContainer,
  LocationContainer,
  OverviewMainTitle,
  OverviewMainSubtitle,
} from './styled'

import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import VenueHeader from '../../venue/venueHeader/view'
import { getVenue, getVenuePrice, getVenueActivity, createBooking, joinBooking } from 'api'
import { TextField, Link, Chip, Grid, Tooltip, CardMedia, Button, FormControlLabel, Checkbox, Box, Modal } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js';
import noAvatar from '../../../../assets/images/no-avatar.png'
import CircularProgress from '@mui/material/CircularProgress';
import LocationMap from 'components/organisms/locationMap/view'
import { useSnackbar } from 'notistack'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleIcon from '@mui/icons-material/People';
import BoltIcon from '@mui/icons-material/Bolt';
import ModalLogin from 'components/pages/general/modalLogin/view'
import ModalSignup from 'components/pages/general/modalSignup/view';

import * as Icons from '@mui/icons-material';

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale to start the week on Monday


import Badge from '@mui/material/Badge';

import Typography from '@mui/material/Typography';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';

import ImageGallery from "react-image-gallery";
import { useProfile } from 'context';

import 'react-image-gallery/styles/css/image-gallery.css';

import ActivityListItem from '../../community/activityListItem/view';
import WeekSlider from 'components/organisms/weekSlider/view';


dayjs.locale('en-gb'); // Set the locale for dayjs


const VenueSingle = () => {

  const { spaces, setSpaces, bookingType, setBookingType } = useProfile();

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams();
  const { slug } = params
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token') != null)
  const [venue, setVenue] = useState(null)

  const [totalPrice, setTotalPrice] = useState(0);
  const [center, setCenter] = useState(null)
  const [gallery, setGallery] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const [images, setImages] = useState([])
  const [amenities, setAmenities] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [quickCheckout, setQuickCheckout] = useState(false)
  const [prices, setPrices] = useState([])
  const [activity, setActivity] = useState([])

  const [loginMode, setLoginMode] = useState(true)
  const [defaultEmail, setDefaultEmail] = useState(null)
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    button: '',
    action: ''
  });
  const [modalOpen, setModalOpen] = useState(false)
  const [modalCode, setModalCode] = useState(false)

  const [requestLogin, setRequestLogin] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const dateParam = searchParams.get('date');
  console.log('Date param is ', dateParam)

  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(dateParam ? dayjs(dateParam) : null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(true);

  const [openingHoursData, setOpeningHours] = useState([
    { day: 'Monday', time: 'Closed' },
    { day: 'Tuesday', time: 'Closed' },
    { day: 'Wednesday', time: 'Closed' },
    { day: 'Thursday', time: 'Closed' },
    { day: 'Friday', time: 'Closed' },
    { day: 'Saturday', time: 'Closed' },
    { day: 'Sunday', time: 'Closed' },
  ]);


  const [errors, setErrors] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: '',
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: false,
  })

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleLogin = (result) => {
    console.log('Login result', result)
    //setRequestLogin(false)
    doBooking(true)
  }

  const handleSignupSuccess = (email) => {
    console.log('Receive email is ', email)
    setDefaultEmail(email)
    setModalContent({
      title: 'Signup Successful!',
      message: 'We have sent you an email to verify your account. Please check your inbox and click the link to verify your account.',
      button: 'Yes, I have verified my email address',
      action: 'navigate'
    });
    setModalOpen(true);
  };


  function navigateItem(type, communitySlug, eventSlug) {

    if (type === 'group_booking') {
      navigate(`/community/${communitySlug}/booking/${eventSlug}`);
    }

    if (type === 'event') {
      navigate(`/events/${slug}`);
    }


  }

  const selectBooking = (booking) => {

    setSelectedBooking(booking)
    setQuickCheckout(true)


  }

  const getDayName = (day) => {
    switch (day) {
      case 0:
        return 'Monday'
      case 1:
        return 'Tuesday'
      case 2:
        return 'Wednesday'
      case 3:
        return 'Thursday'
      case 4:
        return 'Friday'
      case 5:
        return 'Saturday'
      case 6:
        return 'Sunday'
      default:
        return ''
    }
  }

  const getIconComponent = (iconName) => {
    return Icons[iconName] || Icons.Help; // Default to HelpIcon if not found
  };

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }



  const handleInputChange = (event) => {
    if (event.target.name === 'name') setErrors({ ...errors, name: '' })
    if (event.target.name === 'email') setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const doBooking = (userLoggedIn) => {
    if (!userLoggedIn) {
      console.log('User is not logged in')
      setRequestLogin(true)
      setLoginMode(true)
      return
    }
    console.log('Selected date is', selectedDate)
    console.log('Selected price is', selectedPrice)
    const postData = {
      "bookings": [
        {
          number_spaces: spaces,
          booking_type: bookingType,
          venue: slug,
          start: selectedDate.format('YYYY-MM-DD'),
          price: totalPrice,
          instant_booking: selectedPrice?.instant_booking,
        }
      ]
    }
    console.log('Post data is ', postData)
    createBooking(postData).then(r => {
      console.log(r)
      setSessionId(r.sessionId)
    })
      .catch(error => console.log('The error is -> ', error))

  };

  const joinActivity = () => {
    const postData = {
      "bookings": [
        {
          code: selectedBooking?.code,
          number_spaces: 1,
          price: selectedBooking?.price,
        }
      ]
    }
    joinBooking(postData).then(r => {
      setSessionId(r.sessionId)
      setQuickCheckout(false)
    }).catch(error => {
      enqueueSnackbar(t('booking.message.error'), {
        variant: 'error',
      })
      setQuickCheckout(false)
    })
  };

  function getVenueInfo(slug) {
    getVenue(slug).then(r => {
      const locationCenter = { lat: r?.location[0], lng: r?.location[1] }
      setCenter(locationCenter)
      setVenue(r)
      if (r.gallery) {
        const galleryImages = r.gallery.map(g => {
          return {
            original: `${API_BASE_URL}${g.image}`,
            thumbnail: `${API_BASE_URL}${g.image}`,
          }
        })
        setImages(galleryImages)
      }
      if (r.open_time) {
        const openingHours = r.open_time.map(o => {
          const formattedOpen = o.time_start?.split(':').slice(0, 2).join(':');
          const formattedClose = o.time_end?.split(':').slice(0, 2).join(':');

          return {
            day: getDayName(parseInt(o.day)),
            time: `${formattedOpen} - ${formattedClose}`
          }
        });
        const updatedOpeningHours = openingHoursData?.map(day => {
          const matchingDay = openingHours?.find(o => o.day === day.day);
          if (matchingDay) {
            return matchingDay;
          } else {
            return day;
          }
        });

        setOpeningHours(updatedOpeningHours);
      }
      if (r.venue_services) {
        setAmenities(r.venue_services);
      }
      console.log("Changing is loading")
      setIsLoading(false)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function getVenuePricesInfo(firstLoad) {
    getVenuePrice(slug).then(r => {
      setPrices(r.prices)
    })
      .catch(error => console.log('The error is -> ', error))
  }


  function getVenueActivityInfo(firstLoad) {
    getVenueActivity(slug).then(r => {
      setActivity(r.upcoming_activities)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return `${formattedDate}`;
  }


  useEffect(() => {
    console.log('Selected price changed ', selectedPrice, spaces)
    console.log('Selected date is ', selectedDate)
    if (selectedPrice) {
      if (selectedDate == null) setSelectedDate(dayjs(selectedPrice?.start));
      // Find the price for the selected date
      // If available spaces are less than selected spaces, reset prices
      if (selectedPrice && selectedPrice?.available < spaces) {
        setTotalPrice(0);
      } else {
        const dailyPrice = selectedPrice ? parseFloat(selectedPrice?.price) : 0;
        setTotalPrice(dailyPrice * spaces);
      }
    } else {
      setTotalPrice(0);
    }
  }, [spaces, selectedPrice]);

  useEffect(() => {
    console.log('Things rae changing for no reason whatsoever')
    getVenueInfo(slug)
    getVenuePricesInfo(true)
    getVenueActivityInfo(true)
  }, [])

  useEffect(() => {
    console.log('Session ID is ', sessionId)
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])

  const handleDateSelect = (priceObject) => {
    console.log('Price Object', priceObject)
    console.log('Selected date is ', dayjs(priceObject?.start))
    // Handle date selection logic
    //const selectedDate = dayjs(date);
    const newURL = `/venue/${slug}?date=${dayjs(priceObject?.start).format('YYYY-MM-DD')}`;
    
    // Update the URL without reloading the page
    navigate(newURL);
    if (priceObject) {
      setSelectedPrice(priceObject);
    }

  };

  const avatar = venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar
  const background = venue?.background ? `${API_BASE_URL}${venue?.background}` : noAvatar

  return (
    <Container>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // Make sure this covers the full viewport height
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: add slight background
            zIndex: 9999, // Ensure spinner is on top
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <VenueHeader title={venue?.name} subtitle={venue?.single_address} avatar={avatar} background={background} />
          <Grid container>
            <Grid item xs={12}>

              <SecondRowContainer>

                <div>
                  {/* Tab content sections */}
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <OverviewMainTitle>{venue?.name}</OverviewMainTitle>
                      <OverviewMainSubtitle>{venue?.single_address}</OverviewMainSubtitle>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <OverviewLeft>

                        <OverviewTitle>About us</OverviewTitle>
                        <div dangerouslySetInnerHTML={{ __html: venue?.long_description }} />
                        <div>
                          <ImageGallery items={images} />
                        </div>

                        <OverviewTitle>Amenities</OverviewTitle>
                        {amenities.length > 0 ? (
                          <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {amenities.map((amenity) => {
                              const IconComponent = getIconComponent(amenity.icon);
                              return (
                                <Grid item key={amenity.slug} xs={4} sm={3} md={2} style={{ textAlign: 'center' }}>
                                  <Tooltip title={amenity.description} placement="top">
                                    <div>
                                      <IconComponent style={{ fontSize: 40, marginBottom: 8 }} color="icons" />
                                    </div>
                                  </Tooltip>
                                </Grid>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Typography>No amenities available</Typography>
                        )}
                        <OverviewTitle>Location</OverviewTitle>
                        <LocationContainer>
                          <Typography color="textSecondary" sx={{ fontSize: '14px', marginBottom: '16px' }}>
                            {venue?.single_address}
                          </Typography>
                          {venue && venue?.location && venue?.location[0] && venue?.location[1] && (
                            <LocationMap
                              latitude={venue?.location[0]}
                              longitude={venue?.location[1]}
                              name={venue?.name}
                              googleMapsUrl={venue?.google_maps_url}
                            />
                          )}
                        </LocationContainer>

                        <OpeningTimesContainer>
                          <OverviewTitle>Opening Times</OverviewTitle>
                          {openingHoursData?.map(({ day, time }) => (
                            <div key={day} style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography sx={{ fontSize: '0.9rem' }}>{day}</Typography>
                              <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>{time}</Typography>
                            </div>
                          ))}
                        </OpeningTimesContainer>
                      </OverviewLeft>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <OverviewRight>
                        <OverviewTitle>Book a workspace</OverviewTitle>
                        <Grid item xs={12} md={12}>
                          <WeekSlider
                            slug={slug}
                            onDateSelect={handleDateSelect}
                            defaultDate={selectedDate}
                          />
                        </Grid>


                        <Grid item xs={12} md={12} sx={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center', display: 'flex' }}>                          
                          <Grid item xs={6} md={8} spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {selectedPrice?.available >= spaces && <Button variant='contained' color='button_color' onClick={() => doBooking(loggedIn)}>Book {spaces} {bookingType === 'HD' ? 'Hot Desk' : 'Meeting Room'}{spaces > 1 ? 's' : ''} for {totalPrice.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}</Button>}
                            {selectedPrice?.available < spaces && <Button variant='contained' disabled={true} color='button_color' >Not available</Button>}
                            {!selectedPrice?.available && <Button variant='contained' disabled={true} color='button_color' >Please select a date</Button>}

                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ marginTop: '10px', alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        </Grid>
                        <OverviewTitle sx={{ marginTop: '50px' }}>Next activity</OverviewTitle>
                        <Grid item xs={12} md={12}>
                          {!isLoading && activity?.length > 0 ? (
                            activity.map((item, index) => (
                              <ActivityListItem
                                key={index}
                                event={item}
                                selectedOption="upcoming"
                                navigateItem={navigateItem}
                                selectBooking={selectBooking}
                              />
                            ))
                          ) : (
                            !isLoading && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mt: 4,
                                  color: 'text.secondary',
                                }}
                              >
                                <InfoOutlinedIcon sx={{ fontSize: 50, mb: 1 }} />
                                <Typography variant="body1">Sorry, no upcoming activities right now.</Typography>
                              </Box>
                            )
                          )}
                        </Grid>
                      </OverviewRight>
                    </Grid>
                  </Grid>
                </div>
              </SecondRowContainer>
            </Grid>

          </Grid>

          <Modal open={quickCheckout} onClose={() => setQuickCheckout(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalContent>
              <Typography variant="h6" component="h2" sx={{ marginTop: '30px' }}>
                Checkout
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '10px' }}>
                You are about to book a work space at {selectedBooking?.venue} on {formatDate(selectedBooking?.activiyStart)} for £{selectedBooking?.total_price}
              </Typography>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Button size='small' variant='contained' color='secondary' onClick={() => joinActivity()} sx={{ alignItems: 'center' }}>
                  Confirm booking
                </Button>
              </div>
            </ModalContent>
          </Modal>
          <Modal
            open={requestLogin}
            onClose={() => setRequestLogin(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <ModalContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Typography
                  variant="secondary"
                  sx={{
                    fontSize: '1.3rem',
                    fontWeight: 700,
                    lineHeight: '1.5',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    display: 'flex',
                    overflowWrap: 'break-word', // Ensure long words or links break appropriately
                  }}
                >
                  You need to login or sign up to book this space.
                </Typography>
                {loginMode && <ModalLogin showAction={(mode) => setLoginMode(mode)} setLogin={(result) => handleLogin(result)} postAction={{ action: 'addBooking' }} />}
                {!loginMode && <ModalSignup showAction={(mode) => setLoginMode(mode)} onSuccess={handleSignupSuccess} setLogin={handleLogin} postAction={{ action: 'addBooking' }} />}
              </Box>
            </ModalContent>
          </Modal>
        </>
      )}
    </Container>
  )
}

VenueSingle.propTypes = {}

export default VenueSingle
