import * as yup from 'yup'

const validate = yup.object().shape({
  password: yup
  .string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .matches(/[a-zA-Z]/, 'Password must contain at least one Latin letter.')
  .matches(/[0-9]/, 'Password must contain at least one number.')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character.'),
})

export default validate
