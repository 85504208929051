import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { getVenuePrice } from 'api'; // Ensure to import your API function
import { Button, Typography, Box, Grid, Tooltip, CircularProgress } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Import chevron icon
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Import chevron icon
import PeopleIcon from '@mui/icons-material/People'; // Import people icon
import BoltIcon from '@mui/icons-material/Bolt'; // Import thunderbolt icon
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import calendar icon

const WeekSliderView = ({ slug, onDateSelect, defaultDate }) => {
  const [dates, setDates] = useState([]);
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().startOf('week'));
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [selectedDate, setSelectedDate] = useState(null); // Add selectedDate state
  const [weekDays, setWeekDays] = useState([]);
  const dateRefs = useRef({});

  const endDate = startDate.add(7, 'day');

  const generateWeekDays = (start) => {
    const days = [];
    let currentDate = start;
    while (currentDate.isBefore(endDate)) {
      days.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return days;
  };



  useEffect(() => {
    const days = generateWeekDays(startDate);
    setWeekDays(days);
    setDates(days.map(date => date.format('YYYY-MM-DD')));
    setDateRange({
      start: startDate.format('DD MMM'),
      end: endDate.format('DD MMM')
    });

    getVenuePrice(slug, {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD')
    }).then(r => {
      setPrices(r.prices);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
    });
  }, [slug, startDate]);

  const handlePrevWeek = () => {
    setStartDate(prevStartDate => prevStartDate.add(-7, 'day'));
  };

  const handleNextWeek = () => {
    setStartDate(prevStartDate => prevStartDate.add(7, 'day'));
  };

  const handleSelectedDate = (priceObject) => {
    if (priceObject) {
      setSelectedDate(priceObject.start); // Set the selected date
      onDateSelect(priceObject); // Pass the price object instead of just the date
    }
  };

  useEffect(() => {
    console.log('defaultDate', defaultDate);
    if (defaultDate) {
      const formattedDefaultDate = defaultDate.format('YYYY-MM-DD');
      setSelectedDate(formattedDefaultDate);
      const defaultPriceObject = prices.find(p => dayjs(p.start).format('YYYY-MM-DD') === formattedDefaultDate);
      if (defaultPriceObject) {
        console.log('defaultPriceObject', defaultPriceObject);
        onDateSelect(defaultPriceObject);
      }

      // Scroll to the default date if it exists
      if (dateRefs.current[formattedDefaultDate]) {
        dateRefs.current[formattedDefaultDate].scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      }
    }
  }, [defaultDate, weekDays, prices]); // Trigger when defaultDate or weekDays change


  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}>
        <Button onClick={() => handlePrevWeek()} variant="text" sx={{ marginRight: '8px' }}>
          <ChevronLeftIcon />
        </Button>
        <Typography variant="body1">{`${dateRange.start} - ${dateRange.end}`}</Typography>
        <Button onClick={() => handleNextWeek()} variant="text" sx={{ marginLeft: '8px' }}>
          <ChevronRightIcon />
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '16px' }}>
          <Box sx={{ display: 'inline-flex' }}>
            {weekDays.map(date => {
              const priceObject = prices.find(p => dayjs(p.start).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));

              // Format price and original price
              const price = priceObject ? (priceObject.price * 1).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '-';
              const original_price = priceObject?.original_price
                ? (priceObject.original_price * 1).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
                : null;

              const available = priceObject ? priceObject.available : '-';
              const isInstantBooking = priceObject?.instant_booking;
              const hasAvailability = available !== '-';
              const isSelected = selectedDate === date.format('YYYY-MM-DD');
              const hasActivity = priceObject?.activities > 0;

              return (
                <Box
                  key={date.format('YYYY-MM-DD')}
                  onClick={() => handleSelectedDate(priceObject)}
                  sx={{
                    display: 'inline-block',
                    minWidth: hasAvailability ? '100px' : '80px',
                    border: isSelected ? '2px solid #1976d2' : '1px solid #ddd',
                    borderRadius: '4px',
                    margin: '4px',
                    cursor: hasAvailability ? 'pointer' : 'not-allowed',
                    boxShadow: isSelected ? '0 0 8px rgba(0, 0, 0, 0.3)' : 'none',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        backgroundColor: hasAvailability ? 'button_color.main' : '#f5f5f5',
                        color: hasAvailability ? '#fff' : '#000',
                        padding: '4px',
                        textAlign: 'center',
                        margin: '0'
                      }}
                    >
                      {date.format('ddd DD')}
                    </Typography>
                  </Box>
                  <Box sx={{
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90px', // Fixed height to ensure consistent layout
                  }}>
                    {/* Price Display Logic */}
                    <Box sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                      {original_price && original_price !== price ? (
                        <Box>
                          <Typography
                            sx={{
                              textDecoration: 'line-through',
                              color: 'gray',
                              fontSize: '16px'
                            }}
                          >
                            {original_price}
                          </Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'primary.main' }}>
                            {price}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                          {price}
                        </Typography>
                      )}

                    </Box>

                    {/* Icons at the bottom */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                      <Grid container>
                        <Grid xs={6}>
                          {isInstantBooking && (
                            <Tooltip title="Instant confirmation booking">
                              <BoltIcon sx={{ fontSize: '20px', color: 'warning.main' }} />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                        >
                          {hasAvailability && (
                            <Tooltip title="Community activities or events on this day">
                              <CalendarTodayIcon sx={{ fontSize: '20px' }} />
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WeekSliderView;
