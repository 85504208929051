import React, { useState } from 'react';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Info, Group, Event, Business, Campaign, Settings, Message } from '@mui/icons-material';
import { StyledAvatar, CommunityBasic, CommunityName, CommunityStatus, AvatarContainer, StyledListItem } from './styled';

const menuItems = [
  { key: 'messages', icon: <Message />, text: 'Hub', authRequired: true }, // Requires authentication
  { key: 'about_us', icon: <Info />, text: 'About us', authRequired: false },
  { key: 'members', icon: <Group />, text: 'Members', authRequired: false },
  { key: 'activity', icon: <Event />, text: 'Activity', authRequired: false },  
  { key: 'partners', icon: <Business />, text: 'Partners', role: 'admin', authRequired: true }, // Admin and requires authentication
  { key: 'marketing', icon: <Campaign />, text: 'Marketing', role: 'admin', authRequired: true }, // Admin and requires authentication
  { key: 'manage', icon: <Settings />, text: 'Manage', role: 'admin', authRequired: true }, // Admin and requires authentication
  
];

const SideMenu = ({ open, onClose, changeTab, avatar, title, subtitle, role, isAuthenticated, value }) => {
  const [selectedIndex, setSelectedIndex] = useState(value);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    changeTab(index);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
      }}
    >
      <AvatarContainer>
        <StyledAvatar alt="Avatar" src={avatar} />
      </AvatarContainer>

      <CommunityBasic>
        <CommunityName>{title}</CommunityName>
        <CommunityStatus>{subtitle}</CommunityStatus>
      </CommunityBasic>
      <Divider />
      <List>
        {menuItems.map((item, index) => {
          if (item.authRequired && !isAuthenticated) return null;
          if (item.role && item.role !== role) return null; // Skip items not allowed for current role
          return (
            <StyledListItem
              key={item.key}
              selected={parseInt(selectedIndex) === item.key}
              onClick={() => handleListItemClick(item.key)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default SideMenu;
