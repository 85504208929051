import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { SpacerVerticalSmall, FormItemColumn, ForgotButton } from '../../helpers/formStyle'
import { joinEvent, joinCommunity } from 'api'
import { CircularProgress, Grid, Modal, Button, Stepper, Step, StepLabel } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, InputLabel, MenuItem, Select, Checkbox, ListItemText, Chip, FormControlLabel } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router'

const ModalLogin = ({ showAction, setLogin, postAction }) => {

  const { t } = useTranslation()


  const { doLogin } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })


  const [errors, setErrors] = useState({
    credentials: '',
  })

  const doPostAction = () => {
    console.log(postAction)
    
    if (postAction.action === 'joinEvent') {
      const eventData = {event: postAction.slug}
      navigate(`/event-ticket/${postAction.slug}`);
      setLogin(false)
    }
    if (postAction.action === 'addBooking') {
      console.log('Adding booking')
      setLogin(true)
      return
    }

    
    if (postAction.action === 'joinCommunity') {     
      console.log('JOining community ')  
      const data = {
        community: postAction?.slug,
      }
      joinCommunity(data)
        .then(() => {
          setLogin(true)
          //navigate(`/community/${postAction.slug}`);      
        })
        .catch((error) => {
          setLogin(false)
        })
      
    }
  }

  const mutateLogin = useMutation(data => doLogin(data), {
    onSuccess: result => {
      doPostAction()
    },
    onError: () => {
      setErrors({ ...errors, credentials: 'Sorry, looks like your password or email are not correct. Have you received the "Validate your account" email?' })
      /*
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
      */
    },
  })

  const handleClickForgot = () => {
    window.open('/reset', '_blank');
  }

  const handleLogin = () => {
    mutateLogin.mutate(formData)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <Container>
      <Box container spacing={2}>
        <Grid item xs={12}>
          <FormItemColumn>
            <InputLabel id="email">Your email</InputLabel>
            <TextField
              size='small'
              variant="outlined"
              required
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormItemColumn>
        </Grid>
        <Grid item xs={12}>
          <FormItemColumn>
            <InputLabel id="password">Password</InputLabel>
            <TextField
              size='small'
              variant="outlined"
              fullWidth
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
            />            
          </FormItemColumn>          
        </Grid>        
      </Box>
      <Grid style={{marginBottom:'1em'}}>
      {errors.credentials && (<span style={{ color: 'red', paddingBottom: '1em', marginBottom: '1em' }}>{errors.credentials}</span>)}
      </Grid>      
      <Grid item xs={12} sx={{alignContent: 'end', justifyContent: 'end'}}>
      <ForgotButton onClick={handleClickForgot}>{t('login.forgot')}</ForgotButton>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="button_color"
            onClick={handleLogin}
            fullWidth
          >Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={() => showAction(false)}
            fullWidth
          >I don't have an account, Signup.
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

ModalLogin.propTypes = {
  setLogin: PropTypes.func,
  postAction: PropTypes.object,
}
ModalLogin.defaultProps = {
  setLogin: () => null,
  postAction: {},
}


export default ModalLogin
