import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'
import TextField from '@mui/material/TextField'

const LoginFormView = ({ value, onChange, label, error }) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleChange = event => {
    onChange(event)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      label={label}
      value={value}
      variant='filled'
      onChange={event => handleChange(event)}
      helperText={error?.message}
      error={error}
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

LoginFormView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
}

LoginFormView.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  error: null,
}

export default LoginFormView
