import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { SpacerVerticalSmall, FormItemColumn, ForgotButton } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import { joinEvent, validateVerificationCode, sendVerificationCode } from 'api'
import { CircularProgress, Grid, Modal, Button, Stepper, Step, StepLabel } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, Alert, MenuItem, Select, Checkbox, InputAdornment, IconButton, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router'
import { colors } from 'config/colors'
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { margin } from '@mui/system'

const ModalSignup = ({ showAction, setLogin, onSuccess, postAction }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [ticket, setTicket] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState('signup');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  const { doSignup, doLogin } = useAuth();

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    already: ''
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    privacyPolicyChecked: false,
    action: postAction?.action,
    slug: postAction?.slug,
  })

  const [verificationCode, setVerificationCode] = useState('');

  const doPostAction = () => {
    if (postAction.action === 'joinEvent') {
      const eventData = { event: postAction.slug }
      navigate(`/event-ticket/${postAction.slug}`);
    }
    if (postAction.action === 'joinCommunity') {
      navigate(`/community/${postAction.slug}?doJoin=true`);
    }
  }

  const handleClickForgot = () => {
    window.open('/reset', '_blank');
  }

  const mutateSignup = useMutation(data => doSignup(data), {
    onSuccess: () => {
      //doPostAction();
      console.log('Before submit ', formData.email)
      setStep('verify'); // Move to verification step
      // sendVerificationCode(formData.email);
      sendVerificationCode({email: formData.email});
      onSuccess(formData.email);
    },
    onError: (error) => {
      setErrors({ ...errors, already: 'Sorry, looks like this email is already registered' });
    },
  });

  const handleVerifyCode = () => {
    const data = {
      email: formData.email,
      code: verificationCode,
    };
    validateVerificationCode(data).then((response) => {
      if (response.status === 200) {
        setSuccessMessage('Email verified successfully!');
        doLogin({ email: formData.email, password: formData.password });
        onSuccess(formData.email);        
      } else {
        setError('Invalid verification code. Please try again.');
      }
    });
  };

  const handleSignup = () => {
    const [first_name, ...last_nameParts] = formData.name.split(' ');
    const last_name = last_nameParts.join(' ');

    // Prepare the updated form data
    const updatedFormData = {
      ...formData,
      first_name,
      last_name,
    };

    mutateSignup.mutate(updatedFormData);
  };




  const handleInputChange = (event) => {
    setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    const [firstName, lastName] = value.split(' ');
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      first_name: firstName || '',
      last_name: lastName || '',
    }))
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (formData.privacyPolicyChecked && formData.name !== '' && formData.email !== '') {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [formData])


  return (
    <Container>
      {step === 'signup' && (
        <Box container spacing={2}>
          <Grid item xs={12}>
            <FormItemColumn>
              <Typography variant="body2" color="textSecondary">
                Email
              </Typography>
              <TextField
                size='small'
                variant="outlined"
                required
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (<span style={{ color: colors.orange, marginTop: '10px', fontSize: '13px', textAlign: 'center' }}>{errors.email}</span>)}
            </FormItemColumn>
          </Grid>
          <Grid item xs={12}>
            <FormItemColumn>
              <Typography variant="body2" color="textSecondary">
                Name
              </Typography>
              <TextField
                size='small'
                variant="outlined"
                required
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormItemColumn>
          </Grid>
          <Grid >
            <Grid item xs={12}>
              <FormItemColumn>
                <Typography variant="body2" color="textSecondary">
                  Password
                </Typography>
                <TextField
                  size='small'
                  variant="outlined"
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleTogglePasswordVisibility}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormItemColumn>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          {errors?.already !== '' && <Grid item xs={12}>
            <Alert severity="error" onClose={() => { setErrors({ ...errors, already: '' }) }}>Sorry, this email is already registered.</Alert>
            <ForgotButton onClick={handleClickForgot}>{t('signup.forgot')}</ForgotButton>
          </Grid>}
          <SpacerVerticalSmall style={{ marginTop: '10px' }} />
          <Grid item xs={12}>
            <FormItemColumn style={{ margin: '5px 0px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.privacyPolicyChecked}
                    onChange={(e) => handleCheckboxChange(e, 'privacyPolicyChecked')}
                    name="privacyPolicyChecked"
                    color="primary"
                  />
                }
                label={
                  <span>
                    I accept the{' '}
                    <a href="https://the-epic.space/privacy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </span>
                }
              />
            </FormItemColumn>
          </Grid>
          <Grid container spacing={2} alignItems="center" justifyContent="center">

            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="button_color"
                onClick={handleSignup}
                fullWidth
                disabled={!formValid}
              >Sign up
              </Button>
            </Grid>

          </Grid>
        </Box>
      )}
      {step === 'verify' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Enter the verification code sent to your email:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Grid>
          {error && <Alert severity="error">{error}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleVerifyCode}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="text"
          color="primary"
          onClick={() => showAction(true)}
          fullWidth
        >I already have an account, Login
        </Button>
      </Grid>




    </Container>
  )
}

ModalSignup.propTypes = {
  showAction: PropTypes.func,
  setLogin: PropTypes.func,
  event: PropTypes.object,
}
ModalSignup.defaultProps = {
  showAction: () => null,
  setLogin: () => null,
  event: () => { },
}


export default ModalSignup
