import styled from 'styled-components';

export const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Keep overflow here hidden for fixed layout */
  z-index: 1000;
`;

export const ChatHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChatBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content overflow here */
`;

export const ChatText = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto; /* Scrolls only within this area */
`;

export const ChatFooter = styled.div`
  padding: 10px;
  display: flex;
  align-items: center; /* Align items in the center of footer */
`;

export const Input = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const SendButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.palette.secondary.main}; /* Use the theme palette */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const LeftMessage = styled.div`
  background-color: #f1f0f0;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  text-align: left;
  align-self: flex-start;
  margin-left: 0;
  margin-right: 20%;
  font-size: 0.9rem;
`;

export const RightMessage = styled.div`
  background-color: #dae9ff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  text-align: left;
  align-self: flex-start;
  margin-left: 20%;
  margin-right: 0;
  font-size: 0.9rem;
`;
