import React from 'react'
import PropTypes from 'prop-types'
import TabBar from 'components/molecules/tabBar'
import { Container } from './styled'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom';

export const HeaderToolber = ({ className, tabValue, tabs, rightActions }) => (
  <Container variant='permanent' anchor='left' className={className}>
    <Typography variant='h6' sx={{ marginLeft:'10px'}}>HiveSpace</Typography>
    <TabBar value={tabValue} tabs={tabs} />
    {/* <Typography variant='h6' sx={{ marginLeft:'10px'}} >
      <Link to="/login">Login</Link>
      </Typography> */}
    {rightActions}
  </Container>
)

HeaderToolber.propTypes = {
  className: PropTypes.string,
  tabValue: PropTypes.string,
  tabs: PropTypes.array,
  rightActions: PropTypes.node,
}

HeaderToolber.defaultProps = {
  className: '',
  tabValue: '',
  tabs: [],
  rightActions: null,
}

export default HeaderToolber
