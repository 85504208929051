import { light } from "@mui/material/styles/createPalette";

export const colors = {
  orange: '#FF7A00',
  white: '#FFFFFF',
  black: '#141414',
  gray: '#8A8B90',
  green: '#7bbb3e',
  softgray: '#e9edf5',
  darkgray: '#444444',
  red: '#cc0000',
  warning: ' #ffc300 ',
  blue: '#0F617D',
  darkblue: '#113a47',
}
