import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from '@mui/material';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';
import { getSearchMap } from 'api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'config/keys';
import { useProfile } from 'context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view';
import CompactCard from './compactCard';
import { setDate } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the British locale
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Container } from './styled'; // Import styled components
import useDebounceMapSearch from 'hooks/debounceMapSearch';

const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 72px)', // Full height minus navbar height
};

const defaultCenter = {
  lat: 51.52268795372802,
  lng: -0.08518920377573923,
};

const libraries = ['places'];

const BookingFind = () => {
  const [searchParams] = useSearchParams();
  const [coworkingSpaces, setCoworkingSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [postcode, setPostcode] = useState('');
  const [coordinates, setCoordinates] = useState(defaultCenter);
  const [distance, setDistance] = useState(1);
  const [loading, setLoading] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const { spaces, start, end, setStart } = useProfile();
  const [zoom, setZoom] = useState(12);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const lastCoordinates = useRef(coordinates); // Add this to track last coordinates
  const debouncedCoordinates = useDebounceMapSearch(coordinates, 300);
  const debouncedZoom = useDebounceMapSearch(zoom, 300);



  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleStartDateChange = (date) => {
    setStart(date);
  };

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setCoordinates(newCoordinates);
        setPostcode(place.formatted_address);

        if (mapRef.current) {
          mapRef.current.panTo(newCoordinates);
        }
      }
    }
  };

  const getZoomLevel = (radius) => {
    const equatorLength = 40075004;
    const widthInPixels = window.innerWidth;
    const metersPerPixel = equatorLength / 512;
    let zoomLevel = 0;

    while ((metersPerPixel * widthInPixels) / Math.pow(2, zoomLevel) > radius) {
      zoomLevel++;
    }

    return zoomLevel - 1;
  };

  const getRadiusFromZoom = (zoomLevel) => {
    const metersPerPixel = 156543.03392 * Math.cos(coordinates.lat * Math.PI / 180) / Math.pow(2, zoomLevel);
    const mapHeight = window.innerHeight; // Get the height of the map in pixels
    return (metersPerPixel * mapHeight) / 2; // Approximate radius based on height
  };

  // Fetch coworking spaces when coordinates or distance changes
  useEffect(() => {
    const fetchCoworkingSpaces = async () => {
      const radiusInMeters = getRadiusFromZoom(debouncedZoom);
      console.log(`Radius in meters: ${radiusInMeters}`);

      if (!debouncedCoordinates) return;

      try {
        setLoading(true);
        const params = {
          start: start.format('YYYY-MM-DD'),
          end: end ? end.format('YYYY-MM-DD') : start.format('YYYY-MM-DD'),
          spaces,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          distance: radiusInMeters,//distance * 1609.34,
        };

        const results = await getSearchMap(params);
        setCoworkingSpaces(results);

        /* const new = getZoomLevel(radiusInMeters);

        if (mapRef.current) {
          mapRef.current.setZoom(zoom);
        } */
      } catch (error) {
        console.error('Error fetching coworking spaces:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoworkingSpaces();
  }, [coordinates, spaces, start, end, zoom]);

  const handleZoomChange = () => {
    console.log('Zoom changed ', mapRef?.current?.getZoom());
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  };

  useEffect(() => {
    const locationParam = searchParams.get('location');
    const dateParam = searchParams.get('date');
    const postCodeParam = searchParams.get('postcode');

    if (locationParam) {
      const [lat, lng] = locationParam.split(',').map(Number);
      setCoordinates({ lat, lng });
    }

    if (dateParam) {
      //setDate(dateParam);
      const formattedDate = dayjs(dateParam, 'YYYY-MM-DD'); // Parse the date from query param
      if (formattedDate.isValid()) {
        setStart(formattedDate); // Set the parsed dayjs object as the start date
        console.log(`Formatted Date: ${formattedDate.format('DD/MM/YYYY')}`); // Optional: Log the formatted date
      } else {
        console.error('Invalid date format in query param:', dateParam);
      }

    }

    if (postCodeParam) {
      setPostcode(postCodeParam);
      console.log(`Postcode from query: ${postCodeParam}`); // Use this postcode as needed in your logic
    }

  }, [searchParams]);

  useEffect(() => {
    if (!mapRef.current) return;

    const handleMapIdle = () => {
      const map = mapRef.current;
      const center = map.getCenter();
      const newCoords = {
        lat: center.lat(),
        lng: center.lng()
      };


      // Compare with lastCoordinates.current instead of coordinates state
      if (
        Math.abs(newCoords.lat - lastCoordinates.current.lat) > 0.0001 ||
        Math.abs(newCoords.lng - lastCoordinates.current.lng) > 0.0001
      ) {
        lastCoordinates.current = newCoords; // Update the ref first
        setCoordinates(newCoords);
      }
    };

    const map = mapRef.current;
    map.addListener('idle', handleMapIdle);
    map.addListener('zoom_changed', handleZoomChange);

    return () => {
      if (map) {
        try {
          map.removeListener('zoom_changed', handleZoomChange);
          map.removeListener('idle', handleMapIdle);
        } catch (error) { }
      }
    };
  }, [mapRef.current]); // Only depend on mapRef.current, not coordinates

  useEffect(() => {
    if (selectedSpace) {
      navigate(`/venue/${selectedSpace.slug}?date=${start.format('YYYY-MM-DD')}`);
    }
  }, [selectedSpace, navigate]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <CircularProgress />;


  return (
    <Grid container spacing={2}>

      <Grid item xs={6} sx={{
        height: 'calc(100vh - 72px)',
        display: 'flex', // Use Flexbox
        flexDirection: 'column', // Stack children vertically
      }}>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional background overlay
              zIndex: 1, // Ensure it appears on top
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box
          sx={{
            margin: '5px', // Add 5px margin
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <Autocomplete
                onLoad={setAutocomplete}
                onPlaceChanged={handlePlaceSelect}
              >
                <TextField
                  label="Postcode"
                  variant="outlined"
                  fullWidth
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                  margin="normal"
                  placeholder="Enter a postcode"
                />
              </Autocomplete>
            </Grid>


            <Grid item xs={4} md={4} alignItems="center">
              
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={start}
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small"  />
                    )}
                    inputFormat="DD/MM/YYYY" // Set the date format to British
                  />
                </LocalizationProvider>
              
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            padding: '16px',
            backgroundColor: '#ffffff',
            height: `calc(100% - 8px)`
          }}
        >
          <Grid container spacing={2}>
            {coworkingSpaces.map((space) => (
              <CompactCard
                key={space.id}
                space={space}
                setSelectedSpace={setSelectedSpace}
              />
            ))}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          onZoomChanged={handleZoomChange}
          center={coordinates}
          onLoad={map => mapRef.current = map}
        >
          {coworkingSpaces.map((space) => (
            <Marker
              key={space.id}
              position={{ lng: space?.venue_location[0], lat: space?.venue_location[1] }}
              onClick={() => setSelectedSpace(space)}
            />
          ))}
        </GoogleMap>
      </Grid>
      {/* <FloatingBookingSelector /> */}
    </Grid>
  );
};

export default BookingFind;