import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid, Select, MenuItem, TextField, Button, Modal, Typography, Box, Fab, IconButton, Drawer } from '@mui/material'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import BookingHeader from './header'
import { ModalContent, BookingModalContent } from './styled'
import { use } from 'i18next'
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getBookings, acceptBooking, createBookingPlan, getVenuePrice } from 'api'
import { get } from 'api/utils'
import dayjs, { Dayjs } from 'dayjs';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import GroupBookingForm from '../groupBookingForm/view';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useNavigate } from 'react-router'

const BookingList = ({ slug, communities }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [bookings, setBookings] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(['PA'])
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({})
  const [theBooking, setBooking] = useState({})
  const [messageEditorState, setMessageEditorState] = useState(EditorState.createEmpty())
  const [openConfirm, setOpenConfirm] = useState(false)
  const [bookingConfirm, setBookingConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState({})
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [sessionId, setSessionId] = useState(null)
  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
  const [prices, setPrices] = useState([])
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    status: 'AL',
    venue: '',
    code: '',
    date: '',
    order_by: '',
    order_dir: 'desc',
    page_size: 10,
    page_number: 1
  });


  const typeMapping = {
    HD: 'Hot Desk',
    PR: 'Private Office',
    MR: 'Meeting Room',
    EV: 'Event',
    OT: 'Other'
  }

  const statusMapping = {
    AL: 'All',
    AC: 'Active',
    PP: 'Pending Payment',
    PC: 'Pending Confirmation',
    PN: 'Pending',
    PB: 'Pre-Booked',
    PR: 'Prepaid',
    CA: 'Cancelled',
    GR: 'Finished',
    RJ: 'Rejected'
  };

  const statusOptions = [
    { value: 'AL', label: 'All' },
    { value: 'AC', label: 'Active' },
    { value: 'PP', label: 'Pending Payment' },
    { value: 'PN', label: 'Pending' },
    { value: 'PB', label: 'Pre-Booked' },
    { value: 'PR', label: 'Prepaid' },
    { value: 'CA', label: 'Cancelled' },
    { value: 'GR', label: 'Finished' },
    { value: 'RJ', label: 'Rejected' }
  ];


  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
    inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'] },
    list: { inDropdown: false, options: ['unordered', 'ordered'] },
    textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
    history: { inDropdown: false, options: ['undo', 'redo'] },
  };

  function clearFilters() {
    setFilters({
      status: 'AL',
      venue: '',
      code: '',
      date: '',
      order_by: '',
      order_dir: 'desc',
      page_size: 10,
      page_number: 1
    });
  }


  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;

    // Check if prices is an array
    if (!Array.isArray(prices)) {
      return null; // or handle it in a way that makes sense for your application
    }
    // Check if there is a price for the current day
    const priceInfo = prices?.find((price) => {
      const priceDate = new Date(price.start);
      return priceDate.toISOString().split('T')[0] === props.day.format('YYYY-MM-DD');
    });

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={priceInfo ? `£${priceInfo?.price}` : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  }

  function navigateBooking(slug) {
    navigate(`/venue/booking/${slug}`);
    //window.location.href = '/login'
  }


  function handleInputChange(e) {
    const { name, value } = e.target
    if (name === 'subject') {
      setSubject(value)
      setBooking({ ...theBooking, subject: value })
    }
    if (name === 'message') {
      setMessage(value)
    }
  }


  const doBooking = () => {
    const formattedDate = selectedDate.format('DD/MM/YYYY');

    const data = {
      title: formData.title,
      description: formData.description,
      venue: slug,
      group: formData.group,
      date: formattedDate,
      number_spaces: formData.spaces,
      booking_type: 'HD',
      price: formData.price,
      fee: formData.fee,
    }
    createBookingPlan(data).then(r => {
      setSessionId(r.sessionId)
    })
      .catch(error => console.log('The error is -> ', error))

  };

  function getVenuePricesInfo(firstLoad) {
    getVenuePrice(slug).then(r => {
      setPrices(r.prices)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function doAcceptBooking(slug, action) {
    setConfirmAction({ slug, action })
    setOpenConfirm(true)

  }

  function doConfirm() {
    acceptBooking(confirmAction).then(r => {
      fetchBookings()
      setOpenConfirm(false)
    }).catch(error => console.log('The error is -> ', error))
  }

  // Handle change for rules editor
  const handleMessageEditorChange = (newEditorState) => {
    setMessageEditorState(newEditorState);
    setMessage(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  const handleMonthChange = (date) => {
    setIsLoading(true);
    setHighlightedDays([]);
    //fetchHighlightedDays(date);
    setIsLoading(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setBooking({ ...theBooking, type: value })
  }

  function fetchBookings() {
    getBookings(filters).then(r => {
      setBookings(r?.bookings)
    })
  }

  const handleSortChange = (column) => {
    const newOrderDir = filters.order_by === column && filters.order_dir === 'asc' ? 'desc' : 'asc';
    setFilters({
      ...filters,
      order_by: column,
      order_dir: newOrderDir,
      page_number: 1 // Reset page number when sorting changes
    });
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
      page_number: 1
    });
  };

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page_number: pageNumber
    });
  };

  useEffect(() => {
    fetchBookings()
    getVenuePricesInfo(true)
  }, [])

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])

  useEffect(() => {
    const htmlMessage = convertToHTML(messageEditorState.getCurrentContent())
    setBooking({ ...theBooking, message: htmlMessage })
  }, [message])

  useEffect(() => {
    fetchBookings()

  }, [filters])

  const roleMapping = {
    'FO': 'Founder',
    'AD': 'Admin',
    'MA': 'Manager',
    'PA': 'Participant',
    'GU': 'Guest',
  };

  return (
    <Container>
      <IconButton
        onClick={() => setDrawerOpen(true)}
        sx={{ display: { xs: 'block', md: 'none' }, marginBottom: 2 }}
      >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ padding: 2, width: 250 }}>
          <IconButton onClick={() => setDrawerOpen(false)} sx={{ marginBottom: 2 }}>
            <CloseIcon />
          </IconButton>
          <TextField
            label={t('Venue')}
            name="venue"
            value={filters.venue}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            select
            label={t('Status')}
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={t('Code')}
            name="code"
            value={filters.code}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label={t('Date')}
            name="date"
            type="date"
            value={filters.date}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            select
            label={t('Sort By')}
            name="order_by"
            value={filters.order_by}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="planned_start_day">{t('Day')}</MenuItem>
            <MenuItem value="venue__name">{t('Venue')}</MenuItem>
            <MenuItem value="status">{t('Status')}</MenuItem>
            <MenuItem value="code">{t('Code')}</MenuItem>
          </TextField>
          <Button variant="contained" color="secondary" onClick={clearFilters} fullWidth>
            {t('Clear Filters')}
          </Button>
        </Box>
      </Drawer>
      <Grid container spacing={2} sx={{ display: { xs: 'none', md: 'flex' }, padding: '25px' }}>
        <Grid item xs={2}>
          <TextField
            label={t('Venue')}
            name="venue"
            value={filters.venue}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            label={t('Status')}
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t('Code')}
            name="code"
            value={filters.code}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t('Date')}
            name="date"
            type="date"
            value={filters.date}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            label={t('Sort By')}
            name="order_by"
            value={filters.order_by}
            onChange={handleFilterChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="planned_start_day">{t('Day')}</MenuItem>
            <MenuItem value="venue__name">{t('Venue')}</MenuItem>
            <MenuItem value="status">{t('Status')}</MenuItem>
            <MenuItem value="code">{t('Code')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" fullWidth color="secondary" onClick={clearFilters} sx={{ marginLeft: '5px' }}>{t('Clear Filters')}</Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          {bookings?.length === 0 && (
            <NoResultsPlaceholder firstSentence={t('No bookings found')} lastSentence={t('Create a new booking now')} />
          )}
          {bookings?.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={3} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('planned_start_day')}><Typography variant="body1">{t('Date')}
                  {filters.order_by === 'planned_start_day' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={2} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('tenant__name')}><Typography variant="body1">{t('Tenant')}
                  {filters.order_by === 'tenant__name' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>

                <Grid item xs={1} sx={{ cursor: 'pointer', display: { xs: 'none', md: 'block' } }} onClick={() => handleSortChange('price')}><Typography variant="body1">{t('Price')}
                  {filters.order_by === 'price' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={1} md={1} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('status')}><Typography variant="body1">{t('Status')}
                  {filters.order_by === 'status' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={1} md={1} sx={{ cursor: 'pointer' }} onClick={() => handleSortChange('code')}><Typography variant="body1">{t('Code')}
                  {filters.order_by === 'code' && (
                    <IconButton size="small">
                      {filters.order_dir === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                  )}
                </Typography></Grid>
                <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' } }}><Typography variant="body1">{t('Action')}</Typography></Grid>
                <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}><Typography variant="body1">{t('Info')}</Typography></Grid>
              </Grid>
            </Box>
          )}
          {bookings?.map((item, index) => {
            console.log(item)
            const formattedStartTime = item?.planned_start_time ? dayjs(item.planned_start_time, 'HH:mm').format('HH:mm') : '';
            const formattedEndTime = item?.planned_end_time ? dayjs(item.planned_end_time, 'HH:mm').format('HH:mm') : '';
            const formattedDate = `${dayjs(item?.planned_start_day).format('DD/MM/YYYY')} ${formattedStartTime} - ${formattedEndTime}`;
            const price = (parseFloat(item.price) || 0) + (parseFloat(item.vat_amount) || 0);
            const formattedPrice = isNaN(price) ? '' : `£${price.toFixed(2)}`;

            return (
              <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                <Grid item xs={3}><Typography variant="body2">{formattedDate}</Typography></Grid>
                <Grid item xs={2} sx={{ cursor: 'pointer' }} onClick={() => navigateBooking(item?.slug)}><Typography variant="body2">{item?.tenant?.name}</Typography></Grid>
                <Grid item xs={1}><Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>{formattedPrice}</Typography></Grid>
                <Grid item xs={1} md={1}><Typography variant="body2">{statusMapping[item?.status]}</Typography></Grid>
                <Grid item xs={1} md={1} ><Typography variant="body2">{item?.code}</Typography></Grid>
                <Grid item xs={1}>
                  {item?.status === 'PC' && <Box display="flex" alignItems="center">
                    <CheckCircle color="success" sx={{ marginLeft: 2, fontSize: '24px', cursor: 'pointer' }} onClick={() => doAcceptBooking(item?.slug, 'accept')} />
                    <Cancel color="error" sx={{ marginLeft: 2, fontSize: '24px', cursor: 'pointer' }} onClick={() => doAcceptBooking(item?.slug, 'reject')} />
                  </Box>}
                </Grid>
                <Grid item xs={3} sx={{ overflow: 'ellipsis', display: { xs: 'none', md: 'block' } }}><Typography variant="body2">{item?.plan_name}</Typography></Grid>
              </Grid>
            )
          }
          )}
          {/* Pagination controls */}
          <Box sx={{ marginTop: 2, textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={filters.page_number === 1}
              onClick={() => handlePageChange(filters.page_number - 1)}
              sx={{ marginRight: 1 }}
            >
              {t('Previous Page')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={bookings.length < filters.page_size}
              onClick={() => handlePageChange(filters.page_number + 1)}
            >
              {t('Next Page')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Fab
        color="secondary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => setBookingConfirm(true)}
      >
        <AddIcon />
      </Fab>
      <Modal
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalContent>
          <Box>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              Booking Confirmation
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to {confirmAction.action === 'accept' ? 'confirm' : 'reject'} this booking?
            </Typography>
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button variant="contained" color="secondary" onClick={() => doConfirm()}>
                {confirmAction.action === 'accept' ? 'Confirm' : 'Reject'}
              </Button>
            </Box>

          </Box>
        </ModalContent>
      </Modal>

      <Modal
        open={bookingConfirm}
        onClose={() => setBookingConfirm(false)}
        aria-labelledby="booking-modal-title"
        aria-describedby="booking-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BookingModalContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <GroupBookingForm formData={formData} setFormData={setFormData} communities={communities} />
            </Grid>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate}
                  loading={isLoading}
                  onMonthChange={handleMonthChange}
                  renderLoading={() => <DayCalendarSkeleton />}
                  slots={{
                    day: ServerDay,
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    },
                  }}
                  onChange={(newValue) => setSelectedDate(newValue)}
                />
              </LocalizationProvider>

              <Button variant='contained' color='button_color' onClick={() => doBooking()}>Book</Button>
            </Grid>
          </Grid>
        </BookingModalContent>
      </Modal>
    </Container >
  )
}

BookingList.propTypes = {

}

export default BookingList
