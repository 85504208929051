import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, IconButton, Fab, Modal } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, Visibility } from '@mui/icons-material';
import { DndContext, closestCenter, DragEndEvent, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { API_BASE_URL } from 'config/api';
import { uploadVenueGalleryImage, updateVenueGallery, deleteVenueGalleryItem } from 'api' // Assuming you have an API utility
import { name } from 'dayjs/locale/en-gb';


const updateGalleryItem = (updatedGallery) => {
    console.log('Updated gallery:', updatedGallery);
    try {
        const data = {
            order_line : updatedGallery.order_line,
            slug: updatedGallery.slug,
        }
        const response = updateVenueGallery(data);
        console.log(response);
    } catch (err) {
        console.error(err);
    }    
};

const deleteGalleryItem = (data) => {
    console.log('Delete gallery:', data);
    try {
        const response = deleteVenueGalleryItem(data);
        console.log(response);
    } catch (err) {
        console.error(err);
    }    
};


const addImageToGallery = (photo, order_line, venueSlug) => {

    try {
        console.log('Updated gallery:', venueSlug);
        const response = uploadVenueGalleryImage(photo, order_line, venueSlug);
        console.log(response);
    } catch (err) {
        console.error(err);
    }

    //return new Promise((resolve) => setTimeout(resolve, 500));
};

const SortableImage = ({ image, handleRemove, index, handleImageClick }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: image.id.toString(),
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    const [isDraggingState, setIsDraggingState] = useState(false);

    const handleMouseDown = () => {
        setIsDraggingState(true);
    };

    const handleMouseUp = () => {
        if (isDraggingState) {
            setTimeout(() => setIsDraggingState(false), 0);
        }
    };

    const handleGridClick = (e) => {
        if (!isDraggingState) {
            handleImageClick(image.preview);
        }
    };

    return (
        <Grid
            item
            xs={6}
            sm={4}
            ref={setNodeRef}
            style={style}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onClick={handleGridClick}
        >
            <Box
                {...attributes}
                {...listeners}
                sx={{
                    position: 'relative',
                    cursor: 'grab',
                    '&:active': {
                        cursor: 'grabbing'
                    }
                }}
            >
                {/* Controls at the top */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        display: 'flex',
                        gap: 1,
                        zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '4px',
                        padding: '2px'
                    }}
                    onClick={e => e.stopPropagation()}
                >
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(index);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleImageClick(image.preview);
                        }}
                    >
                        <Visibility />
                    </IconButton>
                </Box>
                <img
                    src={image.preview || image.image}
                    alt="Preview"
                    style={{
                        width: '100%',
                        height: '200px', // Increased height
                        objectFit: 'cover',
                        borderRadius: '8px',
                        pointerEvents: 'none',
                        userSelect: 'none'
                    }}
                />
            </Box>
        </Grid>
    );
};

const Gallery = ({ venueData }) => {
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    useEffect(() => {
        if (venueData?.gallery) {
            const initialImages = venueData?.gallery?.map((item) => ({
                id: item.id,
                preview: `${item?.image?.startsWith('http') ? '' : API_BASE_URL}${item.image}`,
                order_line: item.order_line,
                slug: item.slug,
            }));
            setImages(initialImages);
        }
    }, [venueData]);

    const handleImageChange = (event) => {
        const file = event?.target.files[0];
        if (file) {
            const newImage = {
                id: Date.now(),
                preview: URL.createObjectURL(file), // Temporary preview for UI
                order_line: images.length + 1,
            };

            setImages((prevImages) => [...prevImages, newImage]);

            // Call API to send only the new image as a file, not base64
            console.log('Sending image');
            addImageToGallery(file, newImage?.order_line, venueData?.slug); // Pass the file directly
        }
    };

    const handleRemove = (index) => {
        const updatedImages = [...images];
        // get image slug from images array and selecte index
        const imageSlug = images[index].slug;
        updatedImages.splice(index, 1);
        setImages(updatedImages);
        deleteGalleryItem({slug: imageSlug});
    };

    const onDragEnd = (event) => {
        const { active, over } = event;
        
        // Check if an actual reorder happened
        if (active.id !== over?.id) {
            // Find old and new indices for the images
            const oldIndex = images.findIndex((image) => image.id.toString() === active.id);
            const newIndex = images.findIndex((image) => image.id.toString() === over.id);
    
            // Reorder images
            const reorderedImages = arrayMove(images, oldIndex, newIndex)?.map((image, index) => ({
                ...image,
                order_line: index + 1,  // Update the order line (index + 1)
            }));
    
            // Update state with reordered images
            setImages(reorderedImages);
    
            // Call the API for the two swapped images
            const updatedImage1 = reorderedImages[oldIndex]; // The image that was dragged
            const updatedImage2 = reorderedImages[newIndex]; // The image that was swapped
    
            // Call updateGalleryItem for each image that changed index
            if (updatedImage1) updateGalleryItem(updatedImage1);  // API call for the first image
            if (updatedImage2) updateGalleryItem(updatedImage2);  // API call for the second image
        }
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    return (
        <Box mt={2}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={onDragEnd}
            >
                <SortableContext
                    items={images?.map((image) => image?.id?.toString())}
                    strategy={verticalListSortingStrategy}
                >
                    <Grid container spacing={2} alignItems="center">
                        {images?.map((image, index) => (
                            <SortableImage
                                key={image?.id}
                                image={image}
                                index={index}
                                handleRemove={handleRemove}
                                handleImageClick={handleImageClick}
                            />
                        ))}
                        {/* Add button grid item */}
                        {images?.length < 10 && (
                            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <AddIcon />
                                </Fab>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={(event) => handleImageChange(event)}
                                />
                            </Grid>
                        )}
                    </Grid>
                </SortableContext>
            </DndContext>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    onClick={() => setIsModalOpen(false)}
                >
                    <img
                        src={selectedImage}
                        alt="Enlarged Preview"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default Gallery;