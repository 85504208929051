import { useEffect, useRef, useState } from 'react';

const useDebounceMapSearch = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const lastValueRef = useRef(null); // Store the last value

  useEffect(() => {
    if (lastValueRef.current === value) {
        console.log("Skip updating if the new value is the same as the last");
      // Skip updating if the new value is the same as the last
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      lastValueRef.current = value; // Update the reference only when debouncing completes
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounceMapSearch;
