import React from 'react';
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
} from '@mui/material';
import { API_BASE_URL } from 'config/api';
import noAvatar from 'assets/images/no-avatar.png';
import PeopleIcon from '@mui/icons-material/People';

const formatDistance = (distanceInMeters) => {
  const distanceInMiles = distanceInMeters / 1609.34;

  if (distanceInMiles > 10) {
    return `${Math.round(distanceInMiles)} mi`;
  } else {
    return `${Math.max(distanceInMiles.toFixed(1), 0.1)} mi`;
  }
};

const CompactCard = ({ space, setSelectedSpace }) => (
  <Grid item xs={4} key={space.slug}>
    <Card onClick={() => setSelectedSpace(space)}>
      <CardMedia
        component="img"
        height="140"
        image={space?.image ? `${API_BASE_URL}${space?.image}` : noAvatar}
        alt={space.venue_name}
      />
      <CardContent sx={{ padding: '8px' }}>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {space.venue_name}
        </Typography>
        <Grid container spacing={1} sx={{ marginTop: '8px' }}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <PeopleIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
            <Typography variant="body2" color="text.secondary">
              {space.available_resources} / {space.total_resources}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              £{space.item_price}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {formatDistance(space.venue_distance)}
            </Typography>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default CompactCard;
