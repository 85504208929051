const translation = {
  validations: {
    phone: 'Phone number is not valid',
  },
  general: {
    YES: 'YES',
    NO: 'NO',
    yes: 'yes',
    no: 'no',
  },
  status: {
    pendingC: 'Pending',
  },
  reset: {
    title: 'Password reset',
    subtitle : 'Use this form to receive an email with your password reset instructions.',
    error: 'There was an error while sending the password reset request. Please get in touch with us.',
    reset: 'Send reset instructions',
    success: 'Password reset email sent',
    backLogin: 'Back to login',
    confirm: 'Please enter your new password to confirm the reset.',
    update: 'Update password',
  },
  login: {
    title: 'Log In',
    subtitle: 'Enter your email address and password to log in.',
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    forgot: 'Forgot?',
    logIn: 'Log In',
    join: "Don't have an account? Join",
    error: 'Login error: Are your credentials correct, and is your account validated?',
    name: 'Name',
  },
  signup: {
    title: 'Sign up',
    subtitle: 'Enter your name, email address and password to sign up.',
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    nameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    forgot: 'Forgot? Reset your password',
    signUp: 'Sign Up',
    join: "Already have an account? Login",
    error: 'Email already registered',
    name: 'Name',
  },
  community: {
    role: {
      FO: 'Founder',
      AD: 'Admin',
      MA: 'Manager',
      PA: 'Participant',
      GU: 'Guest',
    }

  },
  event: {
    sections: {
      dashboard: 'Dashboard',
      events: 'Events',
    },
    join: 'Join event',
    joinDisclaimer: 'By filling this form you are joining the event as a guest. Once you submit the form, you should receive an email more details.',
    joinConfirm: 'Please press the button below if you want to join the event.',
    joinDuplicated: 'Sorry, you are already registered for this event. Please check your email for the event link.',
    full: 'Event full',
    update: 'Update Profile',
    bookingType: {
      BO: 'Group Booking',
      IN: 'Important Information',
      SE: 'Partner offer',
      PR: 'Featured project',
      ME: 'Featured member',
    },
  },
  campaign: {
    sections: {
      dashboard: 'Dashboard',
      campaigns: 'Campaigns',
    },
    send: 'Send',
    sendDisclaimer: 'By filling',
    campaignType: {
      EM: 'Email',
      SM: 'SMS',
      PU: 'Push',
      GU: 'Guests',
    },
    campaignTarget: {
      AL: 'All',
      PA: 'Participants',
      GU: 'Guests',
      AD: 'Admin',
    },
    campaignStatus: {
      PE: 'Pending',
      SE: 'Sent',
      FA: 'Failed',
      DR: 'Draft',
    },
  },
  drawer: {
    sections: {
      dashboard: 'Dashboard',
      events: 'Events',
    },
    notifications: 'Notifications',
  },
  profile: {
    tabs: {
      profile: 'Profile',
      theme: 'Theme',
    },
    theme: {
      primaryColor: 'Primary color',
      secondaryColor: 'Secondary color',
      font: 'Font',
      loginImage: 'Login image',
      favicon: 'favicon',
      loginPlaceholder: 'JPG or PNG, no larger than 10MB',
      name: 'name',
      save: 'Save',
      saveChanges: 'Save changes',
    },
    addProfile: 'Add Profile',
  },
  dropzoneInput: {
    placeholder: 'Add',
  },
  booking: {
    message: {
      success: 'Booking successful',
      error: 'Booking failed, please contact support',
    },
  }
}

export default translation
